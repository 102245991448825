import firebase from "firebase/app";
import "firebase/database";
import Ticket from "./Ticket";
import {isset,getDataFrom} from "../utils";

class Technician extends Ticket {
    constructor(params){
        super(params);
    }

    store(){
        return new Promise((resolve,reject)=>{
            super.updateTimestamp();
            let normalized = this.getEmptying();
            
            firebase.database().ref(`issues/${this.issue_id}/technician`).update(normalized).then(()=>{
                console.log('%cthe new technician was successfully saved in the assigned issue','color:#0f0');
                resolve(true);
            }).catch((err)=>reject(err));
        })
    }

    save(){
        super.store();
        return new Promise((resolve,reject)=>{
            super.updateTimestamp();
            let normalized = this.getEmptying();
            
            firebase.database().ref(`issues/${this.issue_id}/technician`).update(normalized).then(()=>{
                console.log('%cthe technician was successfully updated','color:#0f0');
                resolve(true);
            }).catch((err)=>reject(err));
        })
    }

    log(content,sender){
        super.log(content); // Check content and issue_id throw exception

        let log = {
            timestamp: Date.now(),
            text: (isset(content.text)) ? content.text : null,
            image: (isset(content.image)) ? content.image : null,
            from_dni: (isset(sender)) ? sender.from_dni : this.from_dni,
            from_name: (isset(sender)) ? sender.from_name : this.from_name,
        }

        console.log(log);

        return new Promise((resolve,reject)=>{            
            firebase.database().ref(`issues/${this.issue_id}/technician/logs`).push(log).then(()=>{
                console.log('%cthe log was successfully added in the assigned issue','color:#0f0');
                resolve(log.timestamp);
            }).catch((err)=>reject(err));
        })

    }

    refresh(){
        super.refresh();
        return new Promise((resolve)=>{
            getDataFrom(`issues/${this.issue_id}/technician`,true).then((data)=>{
                // console.log('up',data);
                // console.log('this',Object.keys(this));
    
                Object.keys(this).forEach((prop)=>{
                    if(data[prop]!=undefined) this[prop] = data[prop];
                });

                resolve();
            })
        });

    }

    setState(newState){
        super.setState(newState); // Check content and issue_id throw exception

        return new Promise((resolve,reject)=>{            
            firebase.database().ref(`issues/${this.issue_id}/technician/state`).push(newState).then(()=>{
                console.log('%cthe state was updated','color:#0f0');
                resolve(true);
            }).catch((err)=>reject(err));
        })

    }

    setInitialState(){
        return super.setInitialState('technician');
    }
}

export default Technician;