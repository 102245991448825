<template>
    <div>
        <!-- List placeholder -->
        <div class="row" v-if="!trigger.list">
            <div class="col stretch-card">
                <ListaCarga :card="false" rows="10" class="mt-2"/>
            </div>
        </div>
        <!-- List -->
        <div v-else>
            <h4 class="mb-2">Solicitudes de mantenimiento</h4>
            <div class="row mb-2">
                <div class="col-12 col-md mb-2">
                    Filtrar por fecha
                    <div class="d-flex mt-1">
                        <div class="d-flex align-items-center text-dark border border-dark border-right-0 bg-light rounded-left px-2">
                            <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                            Desde
                        </div>
                        <input type="date" class="form-control mr-2" style="border-radius:0 .25rem .25rem 0 !important" v-model="dateFilter.start" >
                        <div class="d-flex align-items-center text-dark border border-dark border-right-0 bg-light rounded-left px-2">
                            <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                            Hasta
                        </div>
                        <input type="date" class="form-control" style="border-radius:0 .25rem .25rem 0 !important" v-model="dateFilter.end" >
                    </div>
                </div>
                <div class="col-12 col-md-3 mb-2">
                    Filtrar por prioridad
                    <div class="d-flex mt-1">
                        <div class="d-flex align-items-center text-dark border border-dark border-right-0 bg-light rounded-left px-2">
                            <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="16" height="16" viewBox="0 0 384 512" fill="currentColor" ><path d="M216 23.86c0-23.8-30.65-32.77-44.15-13.04C48 191.85 224 200 224 288c0 35.63-29.11 64.46-64.85 63.99-35.17-.45-63.15-29.77-63.15-64.94v-85.51c0-21.7-26.47-32.23-41.43-16.5C27.8 213.16 0 261.33 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-193-168-296.14z"/></svg>
                            Prioridad
                        </div>
                        <select class="form-select" style="border-radius:0 .25rem .25rem 0 !important" v-model="priorityFilter">
                            <option value="0" selected>Todas</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-3 mb-2 text-right text-md-left">
                    Establecer filtros<br>
                    <button class="btn btn-sm btn-outline btn-outline-danger mt-1" @click.prevent="clearFilterTable()">Borrar filtros</button>
                    <button class="btn btn-sm btn-primary ml-2 mt-1" @click.prevent="drawTable()">Filtrar</button>
                </div>
            </div>
            
            <div class="table-responsive">

                <table id="issues-table" class="table" style="width:100%">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Operación</th>
                            <th>Motivo</th>
                            <th>Estado</th>
                            <th>Solicitante</th>
                            <th>Comprador</th>
                            <th>Técnico</th>
                            <th>Prioridad</th>
                            <th>Equipamiento</th>
                            <th>Descripción</th>
                            <th>Fecha cierre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(issue, i) in issues" :key="i" >
                            <td>{{dateAndHour(issue.start_date)}}</td>
                            <td>{{issue.operation_name}}</td>
                            <td>{{issue.issue_type}}</td>
                            <td>
                                <div class="badge" :class="'badge-'+getCurrentState(issue.state).color">
                                    {{capitalizeR(getCurrentState(issue.state).label)}}
                                </div>
                            </td>
                            <td>{{(issue.applicant) ? capitalizeEach(issue.applicant.from_name) : 'ERROR APLI'}}</td>
                           

                            <td> {{(isset(issue.buyer)) ? capitalizeEach(issue.buyer.from_name) : 'Sin asignar'}} </td>


                            <td> {{(isset(issue.technician)) ? capitalizeEach(issue.technician.from_name) : 'Sin asignar'}} </td>

                            <td> {{(isset(issue.priority)) ? issue.priority : 5}} </td>

                            <td>{{issue.equipment}}</td>
                            <td>{{issue.description}}</td>
                            
                            <td>{{(getCurrentState(issue.state).close) ? dateFormateR(getCurrentState(issue.state).timestamp) : ''}}</td>

                            <!-- <td class="d-flex flex-wrap w-74"><div class="badge badge-light mr-2 mb-2" v-for="(category,i) in issue.categories" :key="i">{{sortedCategories[category].label}}</div></td> -->
                            <!-- <td>
                                <div class="btn btn btn-primary ml-2" @click.prevent="$emit('show',{...issue,...{fireId:i}})">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="vertical-align: none;" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                    Ver
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import { getDataFrom, showError,capitalize,isset} from "@/utils";
import $ from 'jquery'
import ListaCarga from '@/components/ListaCarga.vue';
/*eslint-disabled*/
import Buyer from '../../Classes/Buyer';
import Technician from '../../Classes/Technician';
// import Issue from '../../Classes/Issue'
/* eslint-enabled */
export default {
    name:'ListIssues',
    components:{ListaCarga,},
    // props: {groups:Object,categories:Array},
    data() {
        return {
            issues:null,
            buyers:null,
            technicians:null,
            setTechnician:null,
            setBuyer:null,
            setPriority:null,

            dateFilter:{
                start:null,
                end:null,
            },
            priorityFilter:0,

            table:null,

            trigger:{
                list:false,
            }
        }
    },
    mounted(){
        // $('#issues-table').dataTable().fnDestroy();

    //    setTimeout(() => {
            let entitites = ['issues','buyers','technicians'];
            let promises = entitites.map((entity) => {
                return getDataFrom(entity,true).then((data)=>{
                    this[entity] = data;
                }).catch((error)=>{showError(error,`No logramos obtener la lista de ${entity}, intente actualizar la página.`)});
            });

            Promise.all(promises).then(()=>{
               this.trigger.list = true;

               this.$nextTick(()=>{
                   let vue = this;
   
                   $.fn.dataTable.ext.search.push( function( settings, data ) {
                       if(vue.dateFilter.start == null && vue.dateFilter.end == null && (vue.priorityFilter == null || vue.priorityFilter == 0)) return true;
   
                       // No matchea hasta que se demuestre lo contrario
                       let matched = false;
   
                       if(vue.dateFilter.start != null) {
                           var min = new Date(vue.dateFilter.start).getTime();
                           var max = new Date(vue.dateFilter.end).getTime();
                           var date = new Date(data[0]).getTime(); 
           
                           if (min == null && max == null) matched = true
                           if (min == null && date <= max) matched = true
                           if (max == null && date >= min) matched = true
                           if (date <= max && date >= min) matched = true
                       }
                       
                       if(matched && vue.priorityFilter == 0) {
                           matched = true;
                       } else if(matched && data[7] == vue.priorityFilter) {
                           return true;
                       } else if(matched && data[7] != vue.priorityFilter){
                           return false;
                       } else if((!isset(vue.dateFilter.start) || !isset(vue.dateFilter.end)) && data[7] == vue.priorityFilter){
                           matched = true;
                       }
                       
                       return matched;
                   });
   
   
                   let config = JSON.parse(JSON.stringify(tableConfig));
                   config.buttons = [ 'copy', 'excelHtml5','csv', 'pdf' ];
                   config["order"] = [0,'desc'];
                //    config['columnDefs']= [
                //        {
                //            'targets': [0],
                //            'visible': false,
                //            'searchable': false
                //        },
                //    ];
                   
                   // this.table = $('#issues-table').dataTable().fnDestroy();
   
                   this.$nextTick(()=>{
                       this.table = $('#issues-table').DataTable(config);
                   })
               })
                    
    
                
            });
    },
    methods:{
        drawTable(){
            this.table.draw();
        },
        clearFilterTable(){
            this.dateFilter = {
                start:null,
                end:null,
            }

            this.priorityFilter = 0;

            this.table.draw();
        },
        getCurrentState(states){
            let s = {label:'Sin asignar',color:'danger'};
            if(states){
                s = states[Object.keys(states).at(-1)];
            }
            return s;
        },
        handleUpdatePriority(updated){
            if(updated){
                getDataFrom('issues',true).then((data)=>{
                    this.issues = data;
                    this.setPriority = null;
                    this.$nextTick(()=>{
                        $('#issues-table').dataTable().fnDestroy();
                        let config = JSON.parse(JSON.stringify(tableConfig));
                        config.buttons = [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ];
                        config["order"] = [0,'desc'];
                        config['columnDefs']= [
                            {
                                'targets': [0],
                                'visible': false,
                                'searchable': false
                            },
                        ],
                        $('#issues-table').DataTable(config);
                    })
                }).catch((error)=>{showError(error,`No logramos obtener la lista de isues, intente actualizar la página.`)});
            }
        },
        handleSelectOption(selected,entity_type,issueId){
            // Lo ideal seria aprovecha el polimorfismo, pasar como parámetro la instancia de Buyer o Technician
            // Y usar las entidades en forma polimorfica
            // Pero al venir desde el template de Vue no es posible instanciar la clase.

            let entity;
            // Se obtiene el tipo de entidad y se instancia mediante if
            if(entity_type.toLowerCase() == 'buyer'){
                entity = new Buyer();
            }else if(entity_type.toLowerCase() == 'technician'){
                entity = new Technician();
            } else {
                console.error('La entidad <entity_type> no existe');
                return null;
            }

            entity.setTicketSender(selected.dni,selected.name);
            entity.setIssue(issueId);

            entity.setInitialState().then(()=>{
                // console.log('El estado d el ticket fué actualizado');
                entity.store().then(()=>{
                    // console.log('El buyer fue creado y relacionado al issue');
                    getDataFrom('issues',true).then((data)=>{
                        this.issues = data;
                        this.setTechnician = null;
                        this.setBuyer = null;
                        this.$nextTick(()=>{
                            $('#issues-table').dataTable().fnDestroy();
                            $('#issues-table').DataTable(tableConfig);
                        })
                    }).catch((error)=>{showError(error,`No logramos obtener la lista de isues, intente actualizar la página.`)});

                }).catch((error)=>{showError(error,'Hubo un error al actualizar el comprador, presione Cancelar para reintentar o actualice la página.')})
            }).catch((error)=>{showError(error,'Hubo un error al obtener los estados de tickets, es posible solucionarlo actualizando la página, tenga en cuenta que el trabajo no guardado podría perderse.')})

            // console.log('enti',entity); 


        },
        handleMouseOver(event){
            // console.log(event);
            if(event.target.className == "table-pre-select"){
                event.target.childNodes[1].style.display = "block";
            }

        },
        handleMouseLeave(event){
            // console.log(event);
            if(event.target.className == "table-pre-select"){
                event.target.childNodes[1].style.display = "none";
            }

        },
        checkNewLog(logs){
            if(logs==undefined) return false;
            // console.lsog(logs);
            let arrayLog = Object.keys(logs).map((k)=>{
                return logs[k];
            });

            arrayLog.sort((a,b)=>a.timestamp - b.timestamp);
            // console.log(arrayLog);

            // let readedMessages = JSON.parse(localStorage.getItem("readedMessages"));

            // let readedMessages.some((message_id)=>{
            //     return message_id == arrayLog[arrayLog.length - 1]
            // })

            let user = JSON.parse(sessionStorage.getItem('userData'));



            return arrayLog[arrayLog.length - 1].from_dni != user.leg_numdoc;
        },
        isset(e){
            return( e!=undefined && e!=null && e!="");
        },
        capitalizeR(string){return capitalize(string)},
        capitalizeEach(string){
            return string.split(" ").map((s)=>capitalize(s.trim())).join(" ")
        },
        dateFormateR(date){ 
            let d = new Date(date);
            let month = (d.getMonth() + 1).toString();
            let day = d.getDate().toString();
            let year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        },
        dateAndHour(date){
            let d = new Date(date);
            let month = (d.getMonth() + 1).toString();
            let day = d.getDate().toString();
            let year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }

            let hour = d.getHours();
            if (hour.length < 2) {
                hour = '0' + hour;
            }

            let minutes = d.getMinutes();
            if (minutes.length < 2) {
                minutes = '0' + minutes;
            }

            return `${year}-${month}-${day} ${hour}:${minutes}`;
        }
    },
}
</script>

<style scoped>
.table-pre-select > * {
    font-size: 0.88rem !important;
    font-weight: normal !important;
}

.table-pre-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .22rem .8rem;
    border-radius: 0.1875rem;
    transition: all .2s ease-in-out;
    padding-right: .22rem;
    border:1px solid #ededed;
    width: 160px;
}

.table-pre-select:hover{
    background: #ededed;
}

.label-pre-select {
    padding: 0.6rem;
    padding-left: 0;
    width:80%;
    overflow: hidden;
    text-overflow: ellipsis;
}


.table-pre-select .btn{
    padding: .3rem;
    padding-bottom: .2rem;
    transition: all .2s ease-in-out;
    display:none;
}


.btn-circle {
    border-radius: 100%;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-circle svg {
    width: 1.48rem;
    height: 1.48rem;
}


.priority-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 3px;
    padding: 2px;
    width:90%
}
.priority-group > * {
    padding: 0.35rem 0.75rem;
}

.priority-label {
    width: 56%;
    background: #f7f7f7;
    font-size: 0.98rem;
    border-radius: 3px;
    padding: .7rem .75rem;
}

.priority-button {
    border: none;
    width: 40%;
    background: #727cf5;
    border-radius: 3px;
    color: #fff;
    padding: 0.7rem;
}
/* .priority-button svg {
    width:18px;
    height:18px;
} */

.message-alert-dot {
    position: relative;
    background: red;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transform: translate(-8px,-2px);
}


.list-short-text {
    width:10vw;
    text-overflow: ellipsis;
    overflow: hidden;
}

table.dataTable td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
