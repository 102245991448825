<template>
    <div>
        <!-- <h4 class="mb-4">Lista de grupos</h4> -->
        <div class="table-responsive">
            <table ref="table" class="table">
                <thead>
                    <tr>
                        <th>DNI</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(technician, i) in technicians" :key="i" >
                        <td>{{technician.dni}}</td>
                        <td>{{technician.name}}</td>
                        <td>
                            <!-- <div class="btn btn btn-danger ml-2" @click.prevent="$emit('delete',{...technician,...{fireId:i}})" disabled>Eliminar</div> -->
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('assign',{...technician,...{fireId:i}})" disabled>
                                <svg viewBox="0 0 24 24" style="vertical-align: middle !important;" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                Asignar
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import $ from 'jquery'
export default {
    name:'ListTechniciansOperations',
    props: {technicians:Object},
    mounted(){
        let id =  "dt-"+parseInt(Math.random()*1000);
        this.$refs.table.setAttribute('id', id);

        this.$nextTick(() => {
            $('#'+id).dataTable().fnDestroy();
            $('#'+id).DataTable(tableConfig);
        });
    }
}
</script>

<style>

</style>