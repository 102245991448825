<template>
    <div>
        <!-- <h4 class="mb-4">Lista de grupos</h4> -->
        <div class="table-responsive">
            <table ref="table" class="table">
                <thead>
                    <tr>
                        <th>Descripción</th>
                        <th>Color</th>
                        <th>Final</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(state, i) in states" :key="i" >
                        <td>{{capitalizeR(state.label)}}</td>
                        <td><div class="badge" :class="'badge-'+state.color">&nbsp;</div></td>
                        <td>{{(state.close==true)?'Sí':'No'}}</td>
                        <td>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('update',{...state,...{fireId:i}})">Modificar</div>
                            <div class="btn btn btn-danger ml-2" @click.prevent="$emit('delete',{...{fireId:i},...{roleType:this.roleType}})" disabled>Eliminar</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import { capitalize } from "@/utils";
import $ from 'jquery'
export default {
    name:'ListCourses',
    props: {states:Object,roleType:String},
    mounted(){
        let id =  "dt-"+parseInt(Math.random()*1000);
        this.$refs.table.setAttribute('id', id);

        this.$nextTick(() => {
            $('#'+id).dataTable().fnDestroy();
            $('#'+id).DataTable(tableConfig);
        });
    },
    methods:{
        capitalizeR(s){return capitalize(s)}
    }
}
</script>

<style>

</style>