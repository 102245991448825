import firebase from "firebase/app";
import "firebase/database";

import {getDataFrom} from '../utils';

class Issue {
    constructor(params){
        this.technician = (params.technician) ? params.technician : null;
        this.buyer = (params.buyer) ? params.buyer : null;
        this.applicant = (params.applicant) ? params.applicant : null;

        this.start_date =  (params.start_date) ? params.start_date : null;
        this.close_date =  (params.close_date) ? params.close_date : null;

        this.priority =  (params.priority) ? params.priority : 5;

        this.operation_id = (params.operation_id) ? params.operation_id : null;
        this.operation_name = (params.operation_name) ? params.operation_name : null;

        this.description = (params.description) ? params.description : null;
        this.by_food = (params.by_food) ? params.by_food : null;
        this.images = (params.images) ? params.images : [];
        this.equipment = (params.equipment) ? params.equipment : null;
        this.issue_type = (params.issue_type) ? params.issue_type : null;

        this.state = (params.state) ? params.state : null;

        this.id =  (params.id) ? params.id : null;
    }

    toString(){
        return `technician: ${this.technician}, purchase: ${this.purchase}, applicant: ${this.applicant}, start_date: ${this.start_date}, close_date: ${this.close_date}, priority: ${this.priority}`
    }

    refresh(){
        if(this.id==undefined || this.id==null) throw new Error("It is mandatory to assign an <id>, use the setIssue() method first");
        console.log('reloading issue');
        return new Promise((resolve)=>{
            getDataFrom(`issues/${this.id}`,true).then((data)=>{
                Object.keys(this).forEach((prop)=>{
                    if(data[prop]!=undefined) this[prop] = data[prop];
                });
                resolve();
            })
        });

    }

    store(){
        return new Promise((resolve,reject)=>{
            let normalized = this.normalize();

            var newIssueRef = firebase.database().ref('issues').push();
            this.id = newIssueRef.key;
            
            newIssueRef.update(normalized).then(()=>{
                console.log('%cthe issue was successfully saved','color:#0f0');
                resolve(this.id);
            }).catch((err)=>reject(err));
        })
    }

    update(){
        return new Promise((resolve,reject)=>{
            let normalized = this.normalize();
            firebase.database().ref(`issues/${this.id}`).update(normalized).then(()=>{
                console.log('%cthe issue was successfully saved','color:#0f0');
                resolve(true);
            }).catch((err)=>reject(err));
        });
    }

    updateApplicant(applicant_id){
        this.applicant_id = applicant_id;
        return this.update();

        // this.applicant_id = applicant_id;
        // new Promise((resolve,reject)=>{
        //     this.update().then(()=>{
        //         resolve(true);
        //     }).catch((err)=>reject(err));
        // })
    }

    normalize(){
        let clone = JSON.parse(JSON.stringify(this));
        delete clone.id;
        Object.keys(clone).forEach((prop)=>{
            if(clone[prop]==null) clone[prop]=""
        });

        return clone;
    }

    setState(newState){
        return new Promise((resolve,reject)=>{            
            firebase.database().ref(`issues/${this.id}/state`).push(newState).then(()=>{
                console.log('%cthe state was updated','color:#0f0');
                resolve(true);
            }).catch((err)=>reject(err));
        })

    }

    setIssue(id){this.id = id;}
}

export default Issue;