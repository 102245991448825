<template>
    <div>
        <!-- <h4 class="mb-4">Lista de grupos</h4> -->
        <div class="table-responsive">
            <table ref="table" class="table">
                <thead>
                    <tr>
                        <th>DNI</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(role, i) in roles" :key="i" >
                        <td>{{role.dni}}</td>
                        <td>{{role.name}}</td>
                        <td>
                            <!-- <div class="btn btn btn-primary ml-2" @click.prevent="$emit('update',{...role,...{fireId:i}})">Modificar</div> -->
                            <div class="btn btn btn-danger ml-2" @click.prevent="$emit('delete',{...role,...{fireId:i},...{roleType:this.roleType}})" disabled>Eliminar</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import $ from 'jquery'
export default {
    name:'ListCourses',
    props: {roles:Object,roleType:String},
    mounted(){
        let id =  "dt-"+parseInt(Math.random()*1000);
        this.$refs.table.setAttribute('id', id);

        this.$nextTick(() => {
            $('#'+id).dataTable().fnDestroy();
            $('#'+id).DataTable(tableConfig);
        });
    }
}
</script>

<style>

</style>