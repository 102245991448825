<template>
    <form>
        <div class="form-row">
            <div class="col-12 mb-3">
                <label for="user" class="form-label">Seleccione un empleado</label>
                <multiselect 
                    v-if="trigger.personal"
                    :searchable="true"
                    v-model="selected"
                    mode="single"
                    :close-on-select="true"
                    :options="personal"
                    class="multiselect-blue"
                    :placeholder="'Busque y seleccione'"
                    @change="handleUserSelect"
                ></multiselect>
                <div class="text-danger mt-1" v-if="error.dni">{{error.dni}}</div>
            </div>
            <div class="col-12 mb-3">
                <label for="role" class="form-label">Seleccione un tipo de rol</label>
                <select class="form-select" id="role" v-model="roleType">
                    <option :value="r" v-for="(r,i) in roles" :key="i">{{r.label}}</option>
                </select>
                <div class="text-danger mt-1" v-if="error.roleType">{{error.roleType}}</div>
            </div>

            <div class="col-12 text-right mt-2">
                <button class="btn btn-success" @click.prevent="submit">Crear nuevo rol</button>
            </div>
        </div>
    </form>
</template>

<script>
import Multiselect from '@vueform/multiselect'
/*eslint-disable*/


import firebase from "firebase/app";
import "firebase/database";

const fireAplicativos = firebase.apps[1];


import {isEmpty,showError,capitalizeEach} from '@/utils';
import axios from 'axios';
export default {
    name: "CreateRole",
    components:{Multiselect},
    props: {existingRoles:Object},
    data() {
        return {
            roles: [
                {
                    label: "Compras",
                    db: 'buyers',
                    permissions:{
                        id: 26,
                        ModulosAccess: "MantenimientoCompras"
                    }
                },
                {
                    label: "Técnico",
                    db: 'technicians',
                    permissions:{
                        id: 27,
                        ModulosAccess: "MantenimientoTecnico"
                    }
                },
            ],
            role:{
                dni:null,
                name:null,
            },
            roleType:null,
            personal:null,
            trigger:{personal:false},
            selected:null,
            error:{dni:false,roleType:false},
        }
    },
    mounted() {
        this.obtenerPersonal();
    },
    methods:{
        handleUserSelect(selection){
            this.role.dni = selection.leg_numdoc;
            this.role.name = selection.leg_apellido + " " + selection.leg_nombre;
        },
        submit(){
            let valid = true;
            if(isEmpty(this.role.dni)) {
                valid = false;
                this.error.dni = "Seleccione un empleado";
            }

            if(isEmpty(this.roleType)) {
                valid = false;
                this.error.roleType = "Seleccione un tipo de rol";
            }

            if(!valid) return null;

            this.role.name = capitalizeEach(this.role.name);

            firebase.database().ref(this.roleType.db).push(this.role).then(()=>{
                console.log('%c The role was assigned','color:#0f0');

                fireAplicativos.database().ref(`permisos/${this.role.dni}/${this.roleType.permissions.id}`).update({
                    Editor:false,
                    Lector:true,
                    ModulosAccess: this.roleType.permissions.ModulosAccess
                }).then(()=>{
                    this.$emit('end',true);
                }).catch((e)=>{showError(e,"Se produjo un error al asignar permisos, el rol ha sido asignado. Contacte con sistemas para asignar el permiso.")});


            }).catch((e)=>{showError(e,"Se produjo un error al guardar el rol, pulse cancelar si desea reintentar o actualice la página")});

        },
        obtenerPersonal(){
            // console.log(this.existingRoles);
            let rolesByDni = {};
            if(Object.keys(this.existingRoles).length){
                Object.keys(this.existingRoles).forEach((t)=>{
                    Object.keys(this.existingRoles[t]).forEach((id)=>{
                        rolesByDni[this.existingRoles[t][id].dni] = this.existingRoles[t][id]
                    })
                })
            }

            let databases = ['personal','personal_cat','personal_ext','personal_ali'];
            this.personal = [];
            let promises = databases.map((db)=>{
                return axios.get(`https://apprrhh-707b9.firebaseio.com/${db}.json`).then((response)=>{
                    if(response.data!=null) {
                      Object.keys(response.data).forEach((fireId)=>{
                        if (response.data[fireId].leg_fecegr != undefined && response.data[fireId].leg_fecegr.date == "") return; //Saltea los dados de baja
                        if(rolesByDni[response.data[fireId].leg_numdoc] != undefined) return; //Saltea los existentes
                        this.personal.push(
                            {
                                value: response.data[fireId],
                                label: response.data[fireId].leg_apellido + " " + response.data[fireId].leg_nombre
                            }
                        );
                      })
                      // Object.assign(this.personal,response.data);
                    } 
                }).catch((err)=>this.errorLog(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.'));
            });

            Promise.all(promises).then(()=>{
                this.trigger.personal = true;
            });  
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-blue {
    --ms-tag-bg: #DBEAFE;
    --ms-tag-color: #2563EB;
    --ms-ring-color: #2563EB20;
    
    --ms-tag-font-size: 0.8rem;

    --ms-border-color: #282f3ae6;
    --ms-py: 0.375rem;
    --ms-px: 0.75rem;

    --ms-option-font-size: .8rem;
    
    --ms-dropdown-border-color: #282f3ae6;
}
</style>