<template>
    <div class="row">
        <div class="col">
            <div class="flex-table">
                <div class="table-field w-50">
                    Solicita: 
                    <div class="badge badge-light">
                        {{issue.applicant.from_name}}
                    </div>
                </div>
                <div class="table-field w-50">
                    Fecha: 
                    <div class="badge badge-light">
                        {{dateFormateR(issue.start_date)}}
                    </div>
                </div>
                <div class="table-field w-50">
                    Operación: 
                    <div class="badge badge-light">
                        {{issue.operation_name}}
                    </div>
                </div>
                <div class="table-field w-50">
                    Responsable: 
                    <div class="badge badge-light">
                        {{(issue.by_food)?"A cargo de Food Service":"A cargo de la operación"}}
                    </div>
                </div>
                <div class="table-field w-50">
                    Equipo: 
                    <div class="badge badge-light">
                        {{issue.equipment}}
                    </div>
                </div>
                <div class="table-field w-50">
                    Motivo:
                    <div class="badge badge-light">
                        {{(issue.issue_type)}}
                    </div>
                </div>
                <div class="table-field w-100">
                    Descripción:
                    <div class="mt-2 bg-light p-3 rounded">
                        {{issue.description}}
                    </div>
                </div>
                <div class="table-field w-100">
                    Imágenes:
                    <div class="mt-2 d-flex">
                        <img :src="img" v-for="(img,i) in issue.images" :key="i" @click="showModalImage(img)" class="thumb-image mr-2">
                    </div>
                </div>
                <div class="table-field w-100">
                    Estados:
                    <div class="row">
                        <div class="col">
                            <h5>
                                General <div class="badge" :class="'badge-'+getCurrentState(issue.state).color">{{getCurrentState(issue.state).label}}</div>
                            </h5>
                        </div>
                        <div class="col">
                            <h5>
                                Compras 
                                <div v-if="issue.buyer == undefined" class="badge badge-light">Sin asignar</div> 
                                <div v-else class="badge" :class="'badge-'+getCurrentState(issue.buyer.state).color">{{getCurrentState(issue.buyer.state).label}}</div> 
                            </h5>
                        </div>
                        <div class="col">
                            <h5>
                                Mantenimiento 
                                <div v-if="issue.technician == undefined" class="badge badge-light">Sin asignar</div>
                                <div v-else class="badge" :class="'badge-'+getCurrentState(issue.technician.state).color">{{getCurrentState(issue.technician.state).label}}</div>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal v-if="trigger.showImage" :modalImage="true" @click="trigger.showImage=false">
        <img :src="selected" class="image-modal" @click="trigger.showImage=false">
    </modal>
</template>

<script>

/* eslint-disable */
import Issue from '@/Classes/Issue';
// import Buyer from '@/Classes/Buyer';
// import Technician from '@/Classes/Technician';
/* eslint-enable */
import {dateFormat,isset,getDataFrom} from '@/utils'
import Modal from '../Modal.vue'
export default {
    name:'ShowTicket',
    props:{
        issue:Object,
    },
    components: { Modal },
    data() {
        return {
            selected:null,
            trigger:{showImage:false,setState:false},
            newState:{state:null,observation:null},
            states:null,
        }
    },
    mounted() {
        getDataFrom('states/issue',true).then((data)=>{
            this.states = data;
        });

       
    },
    methods: {
        setIssueState(){
            let issue = new Issue(this.issue);
            issue.id = this.issue.fireId;
            issue.state.label = this.newState.state.label;
            issue.state.color = this.newState.state.color;
            issue.state.observation = this.newState.state.observation;
            if (this.newState.state.close != undefined) {
                issue.close_date = Date.now();
            }
            issue.update();
        },
        dateFormateR(date){return dateFormat(date)},
        issetR(p){return isset(p)},
        showModalImage(src){this.selected = src;this.trigger.showImage=true},
        getCurrentState(states){
            let s = {label:'Sin asignar',color:'light'};
            if(states){
                s = states[Object.keys(states).at(-1)];
            }
            return s;
        },
    },
}
</script>

<style scoped>
.list-images {
    display: flex;
    flex-wrap: wrap;
    
}
.list-images img {
    width: 28%;
    height: 120px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.image-modal {
    object-fit: cover;
    max-width: 80vw;
    max-height: 80vh;
    margin:0 auto;
    display: block;
    border-radius: 6px;
}



.flex-table {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.table-field {
    padding: 0.6rem;
    border: 1px solid #e8ebf1;
    font-size: 0.9rem;
    margin-top:-1px;
    margin-right: -1px;
}
.table-field.w-100 {
    margin-right: 1px;
}


.thumb-image {
    border-radius: 6px;
    width: 64px;
    height: 64px;
    object-fit: cover;
}
</style>