import firebase from "firebase/app";
import "firebase/database";
import Ticket from "./Ticket";

class Applicant extends Ticket {
    constructor(params){
        super(params);
        
    }

    toString(){
        return `${super.toString()}}`
    }

    setApplicant(dni,name){
        super.from_dni = dni;
        super.from_name = name;
    }

    store(){
        return new Promise((resolve,reject)=>{
            super.updateTimestamp();
            let normalized = this.getEmptying();
            
            firebase.database().ref(`issues/${this.issue_id}/applicant`).update(normalized).then(()=>{
                console.log('%cthe ticket was successfully saved','color:#0f0');
                resolve(true);
            }).catch((err)=>reject(err));
        })
    }

    setInitialState(){
        return super.setInitialState('applicant');
    }

    // save(){
    //     let normalized = this.getEmptying();

    //     var newIssueRef = firebase.database().ref('issues').push();
    //     var key = newIssueRef.key;
    //     Object.assign(normalized,{id:key});
        
    //     newIssueRef.update(normalized).then(()=>{
    //         console.log('%cthe issue was successfully saved','color:#0f0');
    //         return key;
    //     }).catch((err)=>console.error(err));
    // }

    // getEmptying(){
    //     let clone = JSON.parse(JSON.stringify(this));
    //     Object.keys(clone).forEach((prop)=>{
    //         if(clone[prop]==null) clone[prop]=""
    //     });

    //     return clone;
    // }
}

export default Applicant;