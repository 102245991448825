<template>
    <form>
        <div class="form-row">
            <div class="col-12 col-md mb-3">
                <label for="user" class="form-label">Descripción</label>
                <input type="text" class="form-control" v-model="state.label">
                <div class="text-danger mt-1" v-if="error.label">{{error.label}}</div>
            </div>
            <div class="col-12 col-md mb-3">
                <label for="color" class="form-label">Seleccione un color</label>
                    <select class="form-select mr-3" id="color" v-model="state.color">
                        <!-- <option :value="r" v-for="(r,i) in roles" :key="i">{{r.label}}</option> -->
                        <option value="danger">Rojo</option>
                        <option value="warning">Amarillo</option>
                        <option value="info">Celeste</option>
                        <option value="primary">Azul</option>
                        <option value="success">Verde</option>
                    </select>
                <div class="text-danger mt-1" v-if="error.color">{{error.color}}</div>
            </div>
            <div class="col-12 col-md-3 mb-3" v-if="state.label">
                <label class="form-label w-100">
                    Vista previa
                </label>
                <div class="badge" :class="'badge-'+ state.color" style="padding: 8px 12px 8px;">{{capitalizeR(state.label.trim())}}</div>
            </div>
            <div class="col-12 mb-3">
                <label for="role" class="form-label">Estado final del ticket</label>
                <div class="alert alert-info">Atención! El estado final no permite posteriores actualizaciones del ticket, es decir que cierra el ticket y éste <b>ya no se puede modificar</b>. Se puede activar este parámetro para el estado <b>Cerrado</b> por ejemplo.</div>
                <select class="form-select" id="role" v-model="state.close">
                    <option :value="false">No</option>
                    <option :value="true">Sí</option>
                </select>
                <div class="text-danger mt-1" v-if="error.close">{{error.close}}</div>
            </div>

            


            <div class="col-12 mb-3 mt-2">
                <h5 class="mb-2">Información asociada al estado</h5>
                <div class="alert alert-info">Estos atributos permiten la carga de información extra relacionada al estado, como observaciónes o documentación.</div>
                <div class="d-flex justify-content-between">
                    <div class="checkbox-operation mr-2">
                        <input type="checkbox" class="d-none" id="only-selected">
                        <label 
                            class="checkbox-input checkbox-input-border" 
                            :class="{'checkbox-input-border-success text-success':state.observation}"
                            for="only-selected" 
                            tabindex=0 
                            @keydown.space="handleCheck('observation')" 
                            @click="handleCheck('observation')"
                        >
                            <!-- On -->
                            <!-- <svg v-if="trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="#10b759" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> -->
                            <svg v-if="state.observation" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                            <!-- Off -->
                            <!-- <svg v-if="!trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg> -->
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                            <div class="ml-2" style="padding-top: 2px;">Con observación</div>
                        </label>
                    </div>
                    <div class="checkbox-operation mr-2">
                        <input type="checkbox" class="d-none" id="only-selected">
                        <label 
                            class="checkbox-input checkbox-input-border" 
                            :class="{'checkbox-input-border-success text-success':state.due_date}"
                            for="only-selected" 
                            tabindex=0 
                            @keydown.space="handleCheck('due_date')" 
                            @click="handleCheck('due_date')"
                        >
                            <!-- On -->
                            <!-- <svg v-if="trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="#10b759" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> -->
                            <svg v-if="state.due_date" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                            <!-- Off -->
                            <!-- <svg v-if="!trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg> -->
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                            <div class="ml-2" style="padding-top: 2px;">Con fecha de vencimiento</div>
                        </label>
                    </div>
                    <div class="checkbox-operation">
                        <input type="checkbox" class="d-none" id="only-selected">
                        <label 
                            class="checkbox-input checkbox-input-border" 
                            :class="{'checkbox-input-border-success text-success':state.files}"
                            for="only-selected" 
                            tabindex=0 
                            @keydown.space="handleCheck('files')" 
                            @click="handleCheck('files')"
                        >
                            <!-- On -->
                            <!-- <svg v-if="trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="#10b759" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> -->
                            <svg v-if="state.files" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                            <!-- Off -->
                            <!-- <svg v-if="!trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg> -->
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                            <div class="ml-2" style="padding-top: 2px;">Con archivos adjuntos</div>
                        </label>
                    </div>
                </div>
            </div>
            

            <div class="col-12 text-right mt-2">
                <button class="btn btn-light mr-2" @click.prevent="$emit('end',false)">Cancelar</button>
                <button class="btn btn-success" @click.prevent="submit">Actualizar estado</button>
            </div>
        </div>
    </form>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

import {isEmpty,showError,capitalize} from '@/utils';
// import axios from 'axios';
export default {
    name: "UpdateState",
    props:{roleType:String,selected:Object},
    components:{},
    data() {
        return {
            state: {
                label:null,
                color:'danger',
                close:false,
                due_date:false,
                observation:true,
                files:false,
            },
            trigger:{},
            error:{label:false,color:false,close:false},
        }
    },
    mounted() {
        this.state = JSON.parse(JSON.stringify(this.selected));
        delete this.state['fireId'];
    },
    methods:{
        handleCheck(state_prop){
            this.state[state_prop] = !this.state[state_prop];
            this.$forceUpdate();
        },  
        submit(){
            let valid = true;
            Object.keys(this.state).forEach((p)=>{
                if(isEmpty(this.state[p])){
                    this.error[p] = "El campo es obligatorio"
                    valid = false;
                }
            });

            if(!valid) return null;

            this.state.label = this.state.label.trim().toLowerCase();

            firebase.database().ref(`states/${this.roleType}/${this.selected.fireId}`).update(this.state).then(()=>{
                console.log('%c The state was updated','color:#0f0');
                this.$emit('end',true);
            }).catch((e)=>{showError(e,"Se produjo un error al guardar el estado, pulse cancelar si desea reintentar o actualice la página")});

        },
        capitalizeR(s){return capitalize(s)}
    }
}
</script>

<style>

</style>