// import firebase from "firebase/app";
// import "firebase/database";
import {getDataFrom,isset} from "../utils";

/**
 * estarpia bueno declarar una interfaz para logs Logable
 * Que vaya almacenando en una lista de logs el usuario, el ticket, isuue, estado y mensaje
 */

class Ticket {
    constructor(params){
        if(params){
            this.issue_id = (params.issue_id) ? params.issue_id : null;
            this.state = (params.state) ? params.state : null;
            this.images = (params.images) ? params.images : [];
            this.from_name = (params.from_name) ? params.from_name : null;
            this.from_dni = (params.from_dni) ? params.from_dni : null;
            this.timestamp = (params.timestamp) ? params.timestamp : null;
        }
    }

    toString(){
        return `issue_id: ${this.issue_id}, state: ${this.state}, by_food: ${this.by_food}, images: ${this.images}, from_name: ${this.from_name}, from_dni: ${this.from_dni}`;
    }

    /**
     * Store a new ticket into firebase
     * @returns pushed id
     */
    store(){
        if(this.issue_id==undefined || this.issue_id==null) throw new Error("It is mandatory to assign a <issue_id>, use the SetIssue() method");
        // return new Promise((resolve,reject)=>{
        //     let normalized = this.getEmptying();
    
        //     var newIssueRef = firebase.database().ref(`issues/${this.issue_id}/`).push();
        //     var key = newIssueRef.key;
            
        //     newIssueRef.update(normalized).then(()=>{
        //         console.log('%cthe ticket was successfully saved','color:#0f0');
        //         resolve(key);
        //     }).catch((err)=>reject(err));
        // })
    }

    /**
     * Save the ticket into firebase, update and next maybe store
     * //TODO: traer el metodo store a este, todo en uno.
     * @returns pushed id
     */
    save(){
        if(this.issue_id==undefined || this.issue_id==null) throw new Error("It is mandatory to assign a <issue_id>, use the SetIssue() method first");
        // return new Promise((resolve,reject)=>{
        //     let normalized = this.getEmptying();
    
        //     var newIssueRef = firebase.database().ref(`issues/${this.issue_id}/`).push();
        //     var key = newIssueRef.key;
            
        //     newIssueRef.update(normalized).then(()=>{
        //         console.log('%cthe ticket was successfully saved','color:#0f0');
        //         resolve(key);
        //     }).catch((err)=>reject(err));
        // })
    }

    refresh(){
        if(this.issue_id==undefined || this.issue_id==null) throw new Error("It is mandatory to assign a <issue_id>, use the SetIssue() method first");
    }

    log(content){
        if(!isset(this.issue_id)) throw new Error("It is mandatory to assign a <issue_id>, use the SetIssue() method first");
        if(!isset(content)) throw new Error("The parameter <content> is mandatory, please give me a content");
    }

    getEmptying(){
        let clone = JSON.parse(JSON.stringify(this));
        delete clone.issue_id;
        Object.keys(clone).forEach((prop)=>{
            if(clone[prop]==null) clone[prop] = "";
            if(prop=='from_dni') clone[prop] = parseInt(clone[prop]);
        });

        return clone;
    }

    setIssue(id){
        this.issue_id = id;
    }

    // setState(state){
    //     this.state = state;
    // }

    setState(state){
        if(!isset(this.issue_id)) throw new Error("It is mandatory to assign a <issue_id>, use the SetIssue() method first");
        if(!isset(state.label)) throw new Error("The parameter <label> is mandatory, please give me a content");
        if(!isset(state.from_dni)) throw new Error("The parameter <from_dni> is mandatory, please give me a content");
        if(!isset(state.observation)) throw new Error("The parameter <from_dni> is mandatory, please give me a content");
    }

    updateTimestamp(){
        this.timestamp = Date.now();
    }
    
    setTicketSender(dni,name){
        super.from_dni = dni;
        super.from_name = name;
    }

    setInitialState(entity){
        return new Promise((resolve,reject)=>{
            getDataFrom(`states/${entity}`,true).then((data)=>{
                console.log(data);
                this.state = data[0];
                resolve(this.state);
            }).catch((error)=>reject(error));
        })
    }
}

export default Ticket;