<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mb-0">{{view.description}}</p>
              <div class="w-50 text-left text-md-right">
                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <!-- <button type="button" class="btn btn-primary" disabled v-if="!groups">Obteniendo {{view.title.toLowerCase()}}</button> -->
                  <button type="button" class="btn btn-primary" @click.prevent="refreshTriggers('create')">
                    Crear {{view.title.toLowerCase()}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Create -->
    <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create 
              :existingRoles="roles"
              @end="handleEnd($event)"
            ></create>
          </div>
        </div>
      </div>
    </div>
    <!-- List -->
    <div class="row align-items-start" v-if="trigger.list">
      <div class="col-12 col-md-6 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
              Roles de compras
            </h4>
            <list
              :roles="roles.buyers"
              :roleType="'buyers'"
              @update="handleEvent('update',$event)"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
              Roles de mantenimiento
            </h4>
            <list
              :roles="roles.technicians"
              :roleType="'technicians'"
              @update="handleEvent('update',$event)"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
    </div>
    <!-- Update -->
    <!-- <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update 
              :groups="groups" 
              :selected="selection"
              :categories="categories"
              @edit="handleEvent('update',$event)" 
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
/*eslint-disable*/
import {getDataFrom,capitalize,showError} from '@/utils';
// import $ from "jquery";
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

const fireAplicativos = firebase.apps[1];

import Create from '@/components/Role/CreateRole.vue';
import List from '@/components/Role/ListRoles.vue';
// import Update from '@/components/Group/UpdateGroup.vue';

export default {
  name: "Groups",
  components:{
    Create,
    List,
    // Update,
  },
  data(){
    return {
      view:{title:'Roles',description:'Agregue y modifique roles para empleados de mantenimiento.'},
      entity:'roles',

      selection:null,

      roles:{
        buyers:[],
        technicians:[]
      },

      mantenimientoPermisos:{
        buyers:26,
        technicians:27
      },

      trigger:{
        create:false,
        update:false,
        list:false,
      }
    }
  },
  mounted(){
    this.updateAndList();
  },
  methods:{
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    updateAndList(){
      let databases = ['buyers','technicians'];
      let promises = databases.map((db)=>{
          return getDataFrom(db,true).then((data)=>{
            if(data) { this.roles[db]=data; } 
          }).catch((err)=>this.errorLog(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.'));
      });

      Promise.all(promises).then(()=>{
          this.refreshTriggers('list');
      });  
    },
    handleEnd(update){
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    handleDelete(role){
      console.log(role);
      Swal.fire({
        title: '¿Vas a eliminar el rol?',
        text: "Cuidado! Esta acción no se puede deshacer",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.database().ref(`${role.roleType}/${role.fireId}`).remove().then(()=>{
            fireAplicativos.database().ref(`permisos/${role.dni}/${this.mantenimientoPermisos[role.roleType]}`).remove().then(()=>{
              // Aclaración 23 es el permiso para Mantenimiento
              fireAplicativos.database().ref(`permisos/${role.dni}/23`).remove().then(()=>{

                
                if(role.roleType.toLowerCase() == 'technicians'){
                  getDataFrom('operations',true,`orderBy="technician_dni"&equalTo=${role.dni}`).then((data)=>{
                    let deletePromises = Object.keys(data).map((id)=>{
                      firebase.database().ref(`operations/${id}`).remove().then(()=>{
                        console.log(`%c✓ operation ${data[id].name} was removed`,'color:#0f0');
                      })
                    })

                    Promise.all(deletePromises).then(()=>{
                      console.log(`%c✓ all operations were removed`,'color:#0f0');

                       Swal.fire(
                        'Todo listo!',
                        'El usuario fué eliminado.',
                        'success'
                      )

                      this.updateAndList();
                    })
                  });
                }
              }).catch((e)=>{showError(e,"Se produjo un error al eliminar el permiso, el rol ha sido eliminado. Contacte con sistemas para eliminar el permiso.")});
            }).catch((e)=>{showError(e,"Se produjo un error al eliminar el permiso, el rol ha sido eliminado. Contacte con sistemas para eliminar el permiso.")});
          }).catch((e)=>{showError(e,"Se produjo un error al eliminar rol, cancele para reintentar o actualice la página y vuelva a intentar.")});
        }
      })
    },
    capitalizeR(t){return capitalize(t)}
  }
}
</script>

<style>

</style>