<template>
  <div id="app" v-if="isLogged()">
    <div class="main-wrapper">
      <Menu />
      <div class="page-wrapper">
        <Navbar />
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>
<script>
import APP_DATA from "@/env";

import Menu from "./components/Menu/Menu.vue";
import Navbar from "./components/Navbar/Navbar.vue";

import firebase from "firebase/app";
import "firebase/database";

// DataTable: Una vez importado funciona en todas la demás páginas
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();

// import 'datatables.net-bs';
import jsZip from 'jszip';
// import 'datatables.net-buttons-bs';
// import 'datatables.net-buttons/js/buttons.colVis.min';
// import 'datatables.net-buttons/js/dataTables.buttons.min';
// import 'datatables.net-buttons/js/buttons.flash.min';
// import 'datatables.net-buttons/js/buttons.html5.min';

window.JSZip = jsZip;

// Initialize Firebase
firebase.initializeApp(APP_DATA.db_config[APP_DATA.env]);

/*eslint-disable*/
const fireAplicativos = firebase.initializeApp({
    // Prod
    apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "https://aplicativos.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"

    // Dev
      // apiKey: "AIzaSyAmBaciKateDr18O7meOrL1FzT9hsXGsgY",
      // authDomain: "fs-aplicativos-dev-216c3.firebaseapp.com",
      // projectId: "fs-aplicativos-dev-216c3",
      // storageBucket: "fs-aplicativos-dev-216c3.appspot.com",
      // messagingSenderId: "459963233252",
      // appId: "1:459963233252:web:0e198f2016825566b1cdf1"
}, 'fireAplicativos');
/*eslint-enable*/

export default {
  name: "app",
  components: {
    Menu,
    Navbar,
  },
  methods: {
    isLogged(){ return sessionStorage.getItem("userData") != null }
  },
};
</script>

<style>
.sidebar-brand {
  font-size:20px !important;
}

.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #282f3ae6;
	border-radius: 0.25rem;
}

.input-group-text {
     border-color: #282f3ae6 !important;
    border-radius: 0 .25rem .25rem 0 !important;
    background: #727cf5 !important;
    color: #ffffff !important;
}

.form-select{
  border-color: #282f3ae6 !important;
  border-radius: .25rem !important;
  color: #282f3ae6 !important;
}

.custom-select{
    font-size: .8rem;
}

.form-control, select, .email-compose-fields .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead, .tt-query, .tt-hint {
    font-size: 0.875rem;
}

.table-title {
  position: relative;
  top: 24px;
}
.custom-alert {
  border: none !important;
  border-left: 3px solid #3a7171 !important;
}

.custom-alert-info {
  border-color: #3a7171;
}

.btn svg {
  display: inline-block;
  vertical-align: -.125em;
  width: 1.28rem;
  height: 1.28rem;
}

:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

/**
* Toast
*/
.toast-me {
  padding: 20px;
  position: fixed;
  top:14px;
  right: 14px;
  width: 76vw;
  z-index:99999999999;
  cursor:pointer;
  border-radius:6px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) { 
  .toast-me {
    right: 25px;
    width: 54vw;
  }
}

@media (min-width: 992px) { 
  .toast-me {
    right: 25px;
    width: 38vw;
  }
}

.toast-me div:first-child {
  margin-right: 6px;
}

.toast-me-success {
  background-color: #d5ffd7;
  color: #306d32;
  border: 1px solid rgb(186, 229, 187);
  box-shadow: 2px 2px 16px #37642724;
}

.toast-me-primary {
  background-color: #eceeff;
  color: #4f57b7;
  border: 1px solid #c3c7ef;
  box-shadow: 2px 2px 16px #31377f1c;
}

.toast-me-danger {
  background-color: #ffc9d7;
  color: #972441;
  border: 1px solid #e7a9b9;
  box-shadow: 2px 2px 16px #64272724;
}

.toast-me-info {
  background-color: #d5f3ff;
  color: #1d4d60;
  border: 1px solid #c5e3ef;
  box-shadow: 2px 2px 16px #27426424;
}

.toast-me-warning {
  background-color: #fff5d5;
  color: #60391d;
  border: 1px solid #efd3c5;
  box-shadow: 2px 2px 16px #64412724;
}

.alert {
  border: none !important;
  border-left: 2px solid !important;
}
</style>