<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mb-0">{{view.description}}</p>
              <div class="flex-grow-1 d-flex align-items-center justify-content-start justify-content-md-end title-section-buttons">
                <button class="btn btn-light mr-2" v-if="trigger.listReport" @click.prevent="refreshTriggers('list')">&larr; Volver</button>
                <button class="btn btn-outline btn-outline-primary mr-2" v-else @click.prevent="refreshTriggers('listReport')">
                  <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                  Exportar
                </button>
                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <button type="button" class="btn btn-primary" @click.prevent="refreshTriggers('create')">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    Nuevo ticket
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="row" v-if="!trigger.listReport && isEditor('Mantenimiento') && !trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- Title and states buttons -->
            <div class="row align-items-center mb-3">
              <div class="col-12 col-md-5 col-lg-4">
                <h4>
                  <svg style="vertical-align: top;" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="4"></circle><line x1="4.93" y1="4.93" x2="9.17" y2="9.17"></line><line x1="14.83" y1="14.83" x2="19.07" y2="19.07"></line><line x1="14.83" y1="9.17" x2="19.07" y2="4.93"></line><line x1="14.83" y1="9.17" x2="18.36" y2="5.64"></line><line x1="4.93" y1="19.07" x2="9.17" y2="14.83"></line></svg>
                  Solicitudes
                </h4>
              </div>
              <div class="col d-flex align-items-center justify-content-end">
                <!-- Se muestran tickets:&nbsp;  -->
                <button 
                    class="btn btn-md mr-2" 
                    :class="(issueStateSelected=='warning') ? 'btn-secondary' : 'btn-outline btn-outline-secondary'" 
                    @click="handleTicketsShown('warning')">
                  <div class="dot bg-warning"></div> Solicitud de trabajo
                </button>
                <button 
                    class="btn btn-md mr-2" 
                    :class="(issueStateSelected=='info') ? 'btn-secondary' : 'btn-outline btn-outline-secondary'" 
                    @click="handleTicketsShown('info')">
                  <div class="dot bg-info"></div> Orden de trabajo
                </button>
                <button 
                    class="btn btn-md mr-2" 
                    :class="(issueStateSelected=='success') ? 'btn-secondary' : 'btn-outline btn-outline-secondary'" 
                    @click="handleTicketsShown('success')">
                  <div class="dot bg-success"></div> Resuelto
                </button>
                <button 
                    class="btn btn-md mr-2" 
                    :class="(issueStateSelected=='danger') ? 'btn-secondary' : 'btn-outline btn-outline-secondary'" 
                    @click="handleTicketsShown('danger')">
                  <div class="dot bg-danger"></div> Rechazado
                </button>
              </div>
            </div>
            
            <div class="row mb-3">
              <div class="col">
                <!-- <div class="alert alert-info mb-2">
                  Se muestran tickets de los últimos 30 días, <b @click="showAllTickets()" class="b-link">mostrar todos los tickets</b>.
                </div> -->
                <div class="alert alert-info mb-0">
                  Los filtros desde y hasta se encuentran disponible a través del modulo <button @click.prevent="refreshTriggers('listReport')" class="p-1 ml-2 btn btn-sm btn-outline btn-outline-primary"><svg viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" style="vertical-align: middle !important;
                    width: 12px !important;
                    height: 12px !important;" width="12" height="12"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg> Exportar </button>
                </div>
              </div>
            </div>

            <!-- List -->
            <!-- <ListaCarga v-if="!trigger.list" :card="false" rows="6" class="mt-2"/> -->
            
            <div class="row" v-if="trigger.list">
              <div class="col">
                <!-- <p v-for="(issue) in getIssuesByColor(issueStateSelected)" :key="issue.id">~ {{issue.type}} {{issue.equipment}} {{issue.operation_name}}</p> -->
                <issues-list
                  @update="handleEvent('update',$event)"
                  @delete="handleDelete($event)"
                  @log="handleOpenLogs($event)"
                  @show="handleModal('show',$event)"
                  @closeIssue="handleModal('close',$event)"
                  @updated="getAndShowData()"
                  :buyers="buyers"
                  :technicians="technicians"
                  :givenIssues="getIssuesByColor(issueStateSelected)"
                  :key="listKey"
                ></issues-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    <!-- List -->
    <!-- <div class="row" v-if="trigger.list && isEditor('Mantenimiento')">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list 
              @update="handleEvent('update',$event)"
              @delete="handleDelete($event)"
              @log="handleOpenLogs($event)"
              @show="handleModal('show',$event)"
              @closeIssue="handleModal('close',$event)"
              :key="listKey"
            ></list>
          </div>
          
        </div>
      </div>
    </div> -->

    <modal v-if="trigger.log" :maxWidth="'80%'" @close="trigger.log=false">
      <logs 
        :issue="selected.issue" 
        :from="selected.from" 
        :logs="selected.logs" 
        :entity_type="selected.entity_type" 
        @update="getAndShowData()"
      ></logs>
    </modal>

    <modal v-if="trigger.show" @close="trigger.show=false">
      <show-ticket :issue="selected"></show-ticket>
    </modal>

    <modal v-if="trigger.close" @close="trigger.close=false">
      <!-- <show-ticket :issue="selected"></show-ticket> -->
      <!-- <p>{{selected.fireId}}</p> -->
      <h4 class="mb-3">Actualizar estado general del ticket</h4>
      <StatesManager 
        :entityType="'issue'" 
        :entityData="selected" 
        :issueId="selected.fireId" 
        :issueMode="true"
        :opened="true"
        @updated="getAndShowData()"
        @close="trigger.close=false"
      />

    </modal>
    
    <!-- Report list -->
    <div class="row" v-if="trigger.listReport">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list-report 
              @ended="refreshTriggers('list')"
            ></list-report>
          </div>
        </div>
      </div>
    </div>


    <!-- List Buyer -->
    <div class="row" v-if="trigger.listBuyer && isLector('MantenimientoCompras')">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list-buyer 
              @update="handleEvent('update',$event)"
              @delete="handleDelete($event)"
              @show="handleModal('show',$event)"
              @log="handleOpenLogs($event)"
              :key="listKey"
            ></list-buyer>
          </div>
        </div>
      </div>
    </div>
    <!-- List Technician-->
    <div class="row" v-if="trigger.listBuyer && isLector('MantenimientoTecnico')">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list-technician 
              @update="handleEvent('update',$event)"
              @delete="handleDelete($event)"
              @show="handleModal('show',$event)"
              @log="handleOpenLogs($event)"
              :key="listKey"
            ></list-technician>
          </div>
        </div>
      </div>
    </div>


    <!-- Create -->
    <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create
              @ended="getAndShowData()"
            ></create>
          </div>
        </div>
      </div>
    </div>

    <!-- Update -->
    <!-- <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update 
              :groups="groups" 
              :selected="selection"
              :categories="categories"
              @edit="handleEvent('update',$event)" 
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {getDataFrom,capitalize,getUserBasicData,isset,showError} from '@/utils';
// import $ from "jquery";
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";
/* eslint-disable */
import Issue from '@/Classes/Issue';
import Applicant from '@/Classes/Applicant';
// import Log from '@/Classes/Log';
import Buyer from '@/Classes/Buyer';

// import Create from '@/components/Group/CreateGroup.vue';
import List from '@/components/Manager/ListIssues.vue';
import IssuesList from '@/components/Manager/ListGivenIssues.vue';
import ListReport from '@/components/Manager/ListReport.vue';
import ListBuyer from '@/components/Buyer/ListBuyer.vue';
import ShowTicket from '@/components/Ticket/ShowTicket.vue';
import Logs from '@/components/Log/Logs.vue';
import Modal from '@/components/Modal.vue';
import ListaCarga from '@/components/ListaCarga.vue';


import Create from '@/components/Ticket/CreateTicket';

import ListTechnician from '../components/Technician/ListTechnician.vue';

import StatesManager from '@/components/StatesManager';
// import Update from '@/components/Group/UpdateGroup.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
export default {
  name: "Tickets",
  components:{
    List,
    IssuesList,
    ListBuyer,
    Logs,
    Modal,
    ShowTicket,
    ListTechnician,
    StatesManager,
    Create,
    ListaCarga,
    ListReport
  },
  data(){
    return {
      view:{title:'Tickets',description:'Interactue con las solicitudes a mantenimiento en forma de tickets.'},
      entity:'tickets',

      selection:null,
      groups:null,
      categories:[],

      selected:null,

      issues:null,
      buyers:null,
      technicians:null,

      issueStateSelected:null,

      trigger:{
        list:false,
        listBuyer:false,
        log:false,
        show:false,
        close:false,
        create:false,
        listReport:false,
      },
      allTickets:false,

      listKey:0,

      issue: new Issue({start_date:Date.now()}),
      applicant: new Applicant({}),
      // issue: new Issue({}),
    }
  },
  mounted(){
    let entitites = ['issues','buyers','technicians'];
    let promises = entitites.map((entity) => {
      if (entity == 'issues') {
        let aMonthAgo = new Date();
        aMonthAgo.setMonth(aMonthAgo.getMonth()-1);
        // return getDataFrom(entity,true,`orderBy="start_date"&startAt=${aMonthAgo.getTime()}`).then((data)=>{
        return getDataFrom(entity,true).then((data)=>{
            this[entity] = data;
        }).catch((error)=>{showError(error,`No logramos obtener la lista de ${entity}, intente actualizar la página.`)});
      } else {
        return getDataFrom(entity,true).then((data)=>{
            this[entity] = data;
        }).catch((error)=>{showError(error,`No logramos obtener la lista de ${entity}, intente actualizar la página.`)});
      }
    });

    Promise.all(promises).then(()=>{
      this.handleTicketsShown('warning');
      this.refreshTriggers('list');
    });


    // console.log(this.isLector('MantenimientoCompras'));
    // getDataFrom('states/applicant', true).then((data)=>{
    //     let states = data;

    //     let zonal = {
    //       name: "Pablo Bitis",
    //       dni: 29914758
    //     }

    //     // this.applicant.setApplicant(zonal.dni,zonal.name);
    //     // this.applicant.setState(states[0]);

    //     // console.log(zonal);
    //     // console.log(new Log({from_dni:zonal.dni,from_name:zonal.name}));
    // });

    // getDataFrom('issues/-N-OKP_3eUH1xjSX5iPu',true).then((data)=>{
    //   // console.log(data);

    //   let buyer = new Buyer(data.buyer);
    //   buyer.setIssue('-N-OKP_3eUH1xjSX5iPu');

    //   console.log(buyer);
    // })


  },
  methods:{
    showAllTickets(){
      // console.log('sipi, quiero todos');
      this.allTickets = true;
      this.getAndShowData();

    },
    getAndShowData(){
      this.trigger.list = false;
      let entitites = ['issues','buyers','technicians'];
      let promises = entitites.map((entity) => {
        if (entity == 'issues' && this.allTickets == false) {
          let aMonthAgo = new Date();
          aMonthAgo.setMonth(aMonthAgo.getMonth()-1);
          return getDataFrom(entity,true,`orderBy="start_date"&startAt=${aMonthAgo.getTime()}`).then((data)=>{
              this[entity] = data;
          }).catch((error)=>{showError(error,`No logramos obtener la lista de ${entity}, intente actualizar la página.`)});
        } else {
          return getDataFrom(entity,true).then((data)=>{
              this[entity] = data;
          }).catch((error)=>{showError(error,`No logramos obtener la lista de ${entity}, intente actualizar la página.`)});
        }
      });

      Promise.all(promises).then(()=>{
        this.handleTicketsShown((this.issueStateSelected!=null) ? this.issueStateSelected : 'warning');
        this.refreshTriggers('list');
      });
    },
    handleTicketsShown(color){
      this.issueStateSelected = color;
      this.refreshTriggers();
      this.$nextTick(()=>{
        this.refreshTriggers('list');
      });
      //setTimeout(() => {
      //}, 500);
    },
    getIssuesByColor(color){
      let issues = {};
      Object.keys(this.issues).forEach((i)=>{
        let issue = this.issues[i];
        
        if(issue.state[Object.keys(issue.state).at(-1)].color == color) {
          issue.fireId = i;
          issues[i] = issue;
        }
      });

      return issues;
    },
    handleOpenLogs(selected){
      let user = getUserBasicData();

      this.selected = {
        issue: selected.issue,
        to:selected.entity,
        from: {
          from_dni:user.dni,
          from_name:user.name,
        },
        entity_type:selected.entity_type,
        logs:(isset(selected.entity.logs)) ? selected.entity.logs : null,
      }

      this.trigger.log = true;
    },
    handleSelectOperation(e){
      this.issue.operation_id = e.target.value;
      this.issue.operation_name = e.target.options[e.target.selectedIndex].label;
    },
    submit(){
      this.issue.store().then((id)=>{
        this.applicant.setIssue(id);
        this.applicant.setInitialState().then(()=>{
          this.applicant.store().then(()=>{
            console.log('Ticket almacenado');
          })
        })
      })
    },
    isLector(modulo){
        if (userPermission.find( record => record.ModulosAccess === modulo)) {
          return userPermission.find( record => record.ModulosAccess === modulo).Lector;
        }else{
          return false;
        }
    },
    isEditor(modulo){
        if (userPermission.find( record => record.ModulosAccess === modulo)) {
          return userPermission.find( record => record.ModulosAccess === modulo).Editor;
        }else{
          return false;
        }
    },
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    handleModal(trigger,selection){
      this.selected = selection;
      this.trigger[trigger] = true;
    },
    updateAndList(){
      getDataFrom(this.entity,true).then((data)=>{
          this[this.entity] = (data) ? data : [];
          this.refreshTriggers('list');
      }).catch((err)=>{ console.error(err); alert(`Ocurrio un error al obtener ${this.entity}`); });
    },
    handleEnd(update){
      console.log('entra en handle End');
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    handleDelete(fireId){
      Swal.fire({
        title: '¿Vas a eliminar el grupo?',
        text: "Cuidado! Esta acción no se puede deshacer",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.database().ref(`groups/${fireId}`).remove().then(()=>{
            Swal.fire(
              'Todo listo!',
              'El grupo fué eliminado.',
              'success'
            )

            this.updateAndList();
          })
        }
      })
    },
    capitalizeR(t){return capitalize(t)},
    
  }
}
</script>

<style>
.dot {
  width: .6rem;
  height: .6rem;
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-top;
}

.title-section-buttons svg{
  width: 16px;
  height: 16px;
  vertical-align: bottom;
}

.b-link {
  cursor:pointer;
}

.b-link:hover {
  text-decoration: underline;
}
</style>