<template>
  <div class="wrapper-input">
    <input type="file" :accept="(pdf)?'image/*,.pdf':'image/*'" :multiple="multiple" ref="input" @change="seleccion()"/>
    <div class="d-flex mr-2">
      <div class="btn btn-md d-flex align-items-center" :class="{'btn-success':file, 'w-75': file, 'w-100': !file, 'btn-danger':error.estado, 'btn-primary':!error.estado}" @click="select()">
        <div class="my-3">
            <svg v-if="pdf" class="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" ></path> <polyline points="14 2 14 8 20 8"></polyline> <line x1="12" y1="18" x2="12" y2="12"></line> <line x1="9" y1="15" x2="15" y2="15"></line> </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/> </svg>
            <br><br>
            {{titulo}}
        </div>
      </div>
    </div>
    <div class="wrapper-preview row w-100" v-if="Object.keys(files).length">
      <div v-for="(img,i) in files" :key="i" class="col-6 col-md-3 mb-2 d-flex flex-column align-items-center">
        <img :src="img" class="preview-thumb border border-dark" @click="preview(img)">
        <button class="btn btn-danger mt-1 w-100" @click.prevent="removeImage(i,$event)" :disabled="!checkImageUploaded(img)">
            <svg  viewBox="0 0 24 24" width="16px" height="16px" stroke="#fff" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
            Quitar
        </button>
      </div>
    </div>
    <div class="text-danger mt-1" v-if="error.estado">&otimes; {{error.mensaje}}</div>
  </div>
  <!-- Zoom image -->
  <Modal v-if="modalPreview" @close="modalPreview=false">
    <img :src="selectedImage" @click="modalPreview=false" />
  </Modal>
</template>

<script>
import {putBase64,setProp,removeFile} from '@/utils.js';
import Modal from './Modal.vue';
export default {
  name: "InputFile",
  props: ['titulo','error','multiple','update','pdf'],
  components:{Modal},
  data() {
    return {
      files:[],
      selectedImage:null,
      modalPreview:false,
    };
  },
  mounted(){
    if(this.update!=undefined && this.update){
      this.files = JSON.parse(JSON.stringify(this.update));
      this.files.forEach((e,i)=>{
        if (e==null) {
          this.files.splice(i,1);
        }
      })
      this.$emit('seleccion',this.files);
      this.$forceUpdate();
    }
  },
  methods: {
    select() {
      this.$refs.input.click();
    },
    createImage(file) {
        // Check the file type
        if (file.type == 'application/pdf') {
            // doc = new jsPDF(file);
            // console.log(doc.getCreationDate());
            let vue = this;
            var src = URL.createObjectURL(file);
            // var images = [];

            /*eslint-disable*/
            var loadingTask = pdfjsLib.getDocument(src);
            loadingTask.promise.then(function(doc) {

                var pages = []; while (pages.length < doc.numPages) pages.push(pages.length + 1);
                
                return Promise.all(pages.map(function (num) {
                    doc.getPage(num).then(function(page) {
                        vue.$emit('uploading', true);
                        var scale = 1;
                        var viewport = page.getViewport({ scale: scale, });
                        // Support HiDPI-screens.
                        var outputScale = window.devicePixelRatio || 1;

                        var canvas = document.createElement("canvas");
                        var context = canvas.getContext('2d');

                        canvas.width = Math.floor(viewport.width * outputScale);
                        canvas.height = Math.floor(viewport.height * outputScale);
                        canvas.style.width = Math.floor(viewport.width) + "px";
                        canvas.style.height =  Math.floor(viewport.height) + "px";

                        var transform = outputScale !== 1
                        ? [outputScale, 0, 0, outputScale, 0, 0]
                        : null;

                        var renderContext = {
                            canvasContext: context,
                            transform: transform,
                            viewport: viewport
                        };
                        page.render(renderContext).promise.then(()=>{
                            // document.getElementById('image').src = canvas.toDataURL();
                            let data = canvas.toDataURL();
                            let index = Object.keys(vue.files).length;
                            setProp(vue.files,index,data);
                            
                            vue.$forceUpdate();
                            
                            putBase64(data).then((url)=>{
                                vue.files[index] = url;
                                vue.$emit('seleccion',vue.files);
                                vue.$emit('uploading', false);
                            })
                        });
                    });
                }))
            });

        }else {
            this.$emit('uploading', true);
            let reader = new FileReader()
            reader.onload = (event) => {
                let img = new Image();
                img.src = event.target.result;
    
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = 900
                    const MAX_HEIGHT = 900
                    let width = img.width
                    let height = img.height
    
                    if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                    } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    let data = canvas.toDataURL()
                    let index = Object.keys(this.files).length;

                    setProp(this.files,index,data);

                    this.$forceUpdate();
                    putBase64(data).then((url)=>{
                        this.files[index] = url;
                        this.$emit('seleccion',this.files);
                        this.$emit('uploading', false);
                    })
                }
            }
            reader.readAsDataURL(file)
        }
        /*eslint-enable*/
    },
    seleccion(){
        // console.log(this.$refs.input);
        for (const file of this.$refs.input.files) {
            this.createImage(file);
        }
    },
    checkImageUploaded(url){
      if(url){
        return !url.includes('data:image');
      }
    },
    removeImage(id,e){
      e.target.disabled = true;
      removeFile(this.files[id]).then(()=>{
        // delete this.files[id];
        this.files.splice(id,1)
        this.$forceUpdate();
        this.$emit('seleccion',this.files);
      }).catch(err=>{
          console.info(err);
          this.files.splice(id,1)
        // delete this.files[id];
        this.$forceUpdate();
        this.$emit('seleccion',this.files);
      })
    },
    
    /**
     * Recorta un string si su longitud es mayor a la longitud dada 
     * agrega ".." al inicio en caso de realizar el corte
     * @param str es el string a recortar
     * @param chars la longitud del string limite
     * @return un string recortado o no
     */
    filterName(str,chars){
        if (str.length > chars) {
            return ".." + str.slice(str.length-chars);
        }else {
            return str
        }
    },
    preview(img){
      this.selectedImage = img;
      this.modalPreview = true;
    }
  },
};
</script>

<style scoped>
.wrapper-input {
  display: flex;
  width: 100%;
  /* margin-right: .4rem; */
}

.preview-thumb {
    width: 100%;
    height: 140px;
    border-radius: 6px;
    -o-object-fit: cover;
    object-fit: cover;
    cursor: pointer;
    /* margin-right: .6rem; */
}

input[type="file"] {
  display: none;
}

.icon {
    width: 32px;
    height: 32px;
    stroke: #fff;
}

.icon-success {
    stroke: #10b759;
}

.icon-remove {
    stroke: #ff3366;
    width: 16px;
    height: 16px;
    /* cursor: pointer; */
}

/* .remove-button {
    margin-left: .4rem;
} */

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
