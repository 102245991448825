<template>
    <div>
        <h4 class="mb-4">Solicitudes de mantenimiento</h4>
        <div class="table-responsive" v-if="issues && states">
            <table id="buyer-table" class="table" style="width:100%">
                <thead>
                    <tr>
                        <th></th>
                        <th>Operación</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Motivo</th>
                        <th>Solicitante</th>
                        <th>Técnico</th>
                        <th>Estado compra</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(issue, i) in issues" :key="i" >
                        <td :data-order="issue.start_date"></td>
                        <td>
                            <Popper
                                arrow
                                disableClickAway="false"
                                placement="top"
                                hover
                                :content="issue.operation_name"
                            >
                                <div class="list-short-text">
                                    <!-- {{issue.operation_name}} -->

                                        {{issue.operation_name}}
                                </div>
                            </Popper>
                        </td>
                        <td>{{dateFormateR(issue.start_date)}}</td>
                        <td>
                            <div class="badge" :class="'badge-'+getCurrentState(issue.state).color">
                                {{getCurrentState(issue.state).label}}
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center justify-content-between pr-3">
                                <div style="">
                                    {{issue.issue_type}}
                                    <br>
                                    {{issue.equipment}}
                                </div>
                                <Popper
                                    arrow
                                    disableClickAway="false"
                                    placement="top"
                                    hover
                                    :content="issue.description"
                                >
                                    <button class="btn btn-light btn-circle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                    </button>
                                </Popper>
                            </div>
                        </td>
                        <td>{{issue.applicant.from_name}}</td>
                        <td>{{(isset(issue.technician)) ? issue.technician.from_name : 'Sin asignar'}}</td>
                        <td><div class="badge" :class="'badge-' + getCurrentState(issue.buyer.state).color">{{getCurrentState(issue.buyer.state).label}}</div></td>
                        <!-- <td><div class="badge" :class="'badge-' + getCurrentState(issue.state).color">{{getCurrentState(issue.state).label}}</div></td> -->
                        <td>
                            <div class="d-flex">
                                <button class="btn btn-primary ml-2" @click.prevent="$emit('log',{issue:{...issue,...{fireId:i}},entity:issue.buyer,entity_type:'buyer'})">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-square-text-fill" viewBox="0 0 16 16"> <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/> </svg>
                                    &nbsp;
                                    Gestionar
                                </button>
                                <div class="message-alert-dot" v-if="checkNewLog(issue.buyer.logs)"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>



<script>
import { tableConfig } from "@/env";
import { getDataFrom, showError, dateFormat, capitalize, isset} from "@/utils";
import $ from 'jquery'
// import TableFormSelect from '../TableFormSelect.vue';
import Popper from "vue3-popper";

/*eslint-disabled*/
import Buyer from '../../Classes/Buyer';
// import Technician from '../../Classes/Technician';
// import Issue from '../../Classes/Issue'
/* eslint-enabled */

const user = JSON.parse(sessionStorage.getItem('userData'));
export default {
    name:'ListIssues',
    components:{
        // TableFormSelect,
        Popper
    },
    // props: {groups:Object,categories:Array},
    data() {
        return {
            issues:null,
            states:null,
            setState:null,
        }
    },
    mounted(){
        let promises = [];

        promises.push(
            getDataFrom('issues',true,`orderBy="buyer/from_dni"&equalTo=${user.leg_numdoc}`).then((data)=>{
                this.issues = data;
            }).catch((error)=>{showError(error,`No logramos obtener la lista de tickets, intente actualizar la página.`)})
        );

        promises.push(
            getDataFrom('states/buyer',true).then((data)=>{
                this.states = data;
            }).catch((error)=>{showError(error,`No logramos obtener la lista de estados, intente actualizar la página.`)})
        );

        Promise.all(promises).then(()=>{
            this.$nextTick(()=>{
                $('#buyer-table').dataTable().fnDestroy();
                let config = tableConfig;
                config["order"] = [0,'desc'];
                config['columnDefs']= [
                    {
                        'targets': [0],
                        'visible': false,
                        'searchable': false
                    },
                ],
                $('#buyer-table').DataTable(config);
            })
        });
    },
    methods:{
        getCurrentState(states){
            if (isset(states)) {
                return states[Object.keys(states).at(-1)];
            } else {
                return {label: 'Sin asignar',color: 'danger'};
            }
        },
        handleSelectOption(selected,issueId){
            let buyer = new Buyer(this.issues[issueId].buyer);
            buyer.setIssue(issueId);

            buyer.setState({
                color: selected.color,
                label: selected.label,
            });

            buyer.save().then(() => {
                console.log('Actualizar vista!');
            }).catch((error)=>{showError(error,'Hubo un error al obtener los estados de tickets, es posible solucionarlo actualizando la página, tenga en cuenta que el trabajo no guardado podría perderse.')});
        },
        handleMouseOver(event){
            // console.log(event);
            if(event.target.className == "table-pre-select"){
                event.target.childNodes[1].style.display = "block";
            }

        },
        handleMouseLeave(event){
            // console.log(event);
            if(event.target.className == "table-pre-select"){
                event.target.childNodes[1].style.display = "none";
            }

        },
        checkNewLog(logs){
            if(!isset(logs)) return false;
            // console.log(logs);
            let arrayLog = Object.keys(logs).map((k)=>{
                return logs[k];
            });

            arrayLog.sort((a,b)=>a.timestamp - b.timestamp);

            let user = JSON.parse(sessionStorage.getItem('userData'));

            return arrayLog[arrayLog.length - 1].from_dni != user.leg_numdoc;
        },
        isset(e){
            return( e!=undefined && e!=null && e!="");
        },
        dateFormateR(d){return dateFormat(d)},
        capitalizeR(s){return capitalize(s)},
    },
}
</script>

<style scoped>
.table-pre-select > * {
    font-size: 0.88rem !important;
    font-weight: normal !important;
}

.table-pre-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .22rem .8rem;
    border-radius: 0.1875rem;
    transition: all .2s ease-in-out;
    padding-right: .22rem;
    border:1px solid #ededed;
}

.table-pre-select:hover{
    background: #ededed;
}

.label-pre-select {
    padding: 0.6rem;
    padding-left: 0;
        
}


.table-pre-select .btn{
    padding: 0.4rem;
    padding-bottom: .3rem;
    transition: all .2s ease-in-out;
    display:none;
}


.btn-circle {
    border-radius: 100%;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-circle svg {
    width: 1.48rem;
    height: 1.48rem;
}

.message-alert-dot {
    position: relative;
    background: red;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transform: translate(-8px,-2px);
}

.btn svg {
    vertical-align: middle;
}

.list-short-text {
    width:10vw;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>