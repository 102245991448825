<template>
    <div class="row w-100" v-if="card == false">
        <!-- <div class="col-12">
            <div class="border border-secondary p-3 rounded mb-3">
                <div class="row mb-2">
                    <div class="col-12 col-md mb-2">
                        Filtrar por fecha
                        <div class="load-box"></div>
                    </div>
                    <div class="col-12 col-md-3 mb-2">
                        Filtrar por prioridad
                        <div class="load-box"></div>
                    </div>
                    <div class="col-12 col-md-3 mb-2 text-right text-md-left">
                        Establecer filtros<br>
                        <div class="load-box"></div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-12">
            <div class="w-25"><div class="load-box"></div></div>
            <hr>
            <div v-for="i in parseInt(rows)" :key="i">
                <div class="row mb-2">
                    <div class="col-2">
                        <div class="load-box"></div>
                    </div>
                    <div class="col-2">
                        <div class="load-box"></div>
                    </div>
                    <div class="col-4">
                        <div class="load-box"></div>
                    </div>
                    <div class="col-4">
                        <div class="load-box"></div>
                    </div>
                </div>
                <hr v-if="i<(rows)">
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title w-25"><div class="load-box"></div></div>
                    <hr>
                    <div v-for="i in parseInt(rows)" :key="i">
                        <div class="row mb-2">
                            <div class="col-2">
                                <div class="load-box"></div>
                            </div>
                            <div class="col-2">
                                <div class="load-box"></div>
                            </div>
                            <div class="col-4">
                                <div class="load-box"></div>
                            </div>
                            <div class="col-4">
                                <div class="load-box"></div>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListaCarga", 
    props: {
        card:{type:Boolean, default:true},
        rows:{type:Number, default:4},
    },  
}
</script>

<style scoped>
.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  /* background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%); */
  background: linear-gradient(to right, #eeeeeec9 8%, #dddddd63 18%, #eee 33%);
  height: 32px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .15s linear;
  border-radius: 6px;
}
</style>
