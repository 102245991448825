<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mb-0">{{view.description}}</p>
              <div class="w-50 text-left text-md-right">
                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <!-- <button type="button" class="btn btn-primary" disabled v-if="!groups">Obteniendo {{view.title.toLowerCase()}}</button> -->
                  <button type="button" class="btn btn-primary" @click.prevent="refreshTriggers('create')">
                    Crear {{view.title.toLowerCase()}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Create -->
    <!-- <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create 
              :existingRoles="roles"
              @end="handleEnd($event)"
            ></create>
          </div>
        </div>
      </div>
    </div> -->
    <!-- List -->
    <div class="row" v-if="trigger.list">
      <div class="col-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
              Técnicos existentes
            </h4>
            <list
              :technicians="technicians"
              @assign="handleEvent('update',$event)"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
    </div>
    <!-- Update -->
    <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update 
              :selected="selection"
              :operations="operations"
              @edit="handleEvent('update',$event)" 
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDataFrom,capitalize,showError} from '@/utils';
// import $ from "jquery";
// import Swal from "sweetalert2";

// import firebase from "firebase/app";
// import "firebase/database";

// const fireAplicativos = firebase.apps[1];

// import Create from '@/components/Role/CreateRole.vue';
import List from '@/components/Technician/ListTechniciansOperations.vue';
import Update from '@/components/Technician/ManageOperations.vue';
// import Update from '@/components/Group/UpdateGroup.vue';

export default {
  name: "TechnicianOperationGroups",
  components:{
    // Create,
    List,
    Update,
  },
  data(){
    return {
      view:{title:'Asignación de operaciones',description:'Agregue y modifique las operaciones asignadas a los técnicos de mantenimiento.'},
      entity:'technicians',

      selection:null,

      technicians:null,
      operations:null,
      trigger:{
        create:false,
        update:false,
        list:false,
      }
    }
  },
  mounted(){
    this.updateAndList();

    getDataFrom('https://salida.foodservice.com.ar/api/consumos/operaciones').then((data)=>{
      this.operations = data;

      // this.operations.forEach((op)=>{
        
      // })

    }).catch((err)=>showError(err,`Ocurrio un error al obtener operaciones`));
  },
  methods:{
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    updateAndList(){
      this.refreshTriggers();
      getDataFrom(this.entity,true).then((data)=>{
          this[this.entity] = (data) ? data : [];
          this.refreshTriggers('list');
      }).catch((err)=>showError(err,`Ocurrio un error al obtener ${this.entity}`));
    },
    handleEnd(update){
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    // handleDelete(role){
    //   console.log(role);
    //   Swal.fire({
    //     title: '¿Vas a eliminar el rol?',
    //     text: "Cuidado! Esta acción no se puede deshacer",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Si, eliminar!'
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       firebase.database().ref(`${role.roleType}/${role.fireId}`).remove().then(()=>{
    //         fireAplicativos.database().ref(`permisos/${role.dni}/${this.mantenimientoPermisos[role.roleType]}`).remove().then(()=>{
    //           // Aclaración 23 es el permiso para Mantenimiento
    //           fireAplicativos.database().ref(`permisos/${role.dni}/23`).remove().then(()=>{
    //             Swal.fire(
    //               'Todo listo!',
    //               'El usuario fué eliminado.',
    //               'success'
    //             )

    //             this.updateAndList();
    //           }).catch((e)=>{showError(e,"Se produjo un error al eliminar el permiso, el rol ha sido eliminado. Contacte con sistemas para eliminar el permiso.")});
    //         }).catch((e)=>{showError(e,"Se produjo un error al eliminar el permiso, el rol ha sido eliminado. Contacte con sistemas para eliminar el permiso.")});



    //       }).catch((e)=>{showError(e,"Se produjo un error al eliminar rol, cancele para reintentar o actualice la página y vuelva a intentar.")});
    //     }
    //   })
    // },
    capitalizeR(t){return capitalize(t)}
  }
}
</script>

<style>

</style>