<template>
    <div>
        <form @submit="submit($event)">
            <div class="form-row">
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label">Motivo</label>
                    <input type="text" class="form-control" v-model="issue.description">
                    <div class="text-danger" v-if="error.issue.description">&otimes; {{error.issue.description}}</div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label">Tipo de motivo</label>
                    <select class="form-select" v-model="issue.issue_type">
                        <option value="Envio de equipo">Envío de equipo</option>
                        <option value="Reposición">Reposición</option>
                        <option value="Rotura">Rotura</option>
                    </select>
                    <div class="text-danger" v-if="error.issue.issue_type">{{error.issue.issue_type}}</div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label">Operación</label>
                    <multiselect 
                        v-if="operations!=null"
                        :searchable="true"
                        mode="single"
                        :close-on-select="true"
                        :options="operations"
                        class="multiselect-blue"
                        :placeholder="'Busque y seleccione'"
                        @change="handleOperationSelect"
                    ></multiselect>
                    <div class="text-danger" v-if="error.issue.operation_id">&otimes; {{error.issue.operation_id}}</div>
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label">Equipamiento</label>
                    <multiselect
                        :searchable="true"
                        v-model="issue.equipment"
                        mode="single"
                        :close-on-select="true"
                        :options="equipment"
                        class="multiselect-blue"
                        :placeholder="'Busque y seleccione'"
                    ></multiselect>
                    <div class="text-danger" v-if="error.issue.equipment">&otimes; {{error.issue.equipment}}</div>
                </div>
            </div>


            <div class="form-row">
                <div class="col-12 mb-3">
                    <InputFile :pdf="false" :multiple="true" titulo="Cargar imágenes" @uploading="trigger.uploading = $event" :error="{estado:false}" @seleccion="handleFilesUpload($event)"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12 mb-3">
                    <label class="form-label">Mantenimiento a cargo de Food Service</label>
                    <div class="d-flex">
                        <label for="by_food_true" class="radio-control">
                            <input type="radio" id="by_food_true" :value="true"  v-model="issue.by_food"> Sí
                        </label>
                        <label for="by_food_false" class="radio-control ">
                            <input type="radio" id="by_food_false" :value="false"  v-model="issue.by_food"> No
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col text-right">
                    <button class="btn btn-light" @click.prevent="$emit('ended',false)">Cancelar</button>

                    <button class="btn btn-success ml-3" v-if="trigger.uploading" disabled>
                        <div class="spinner-border" style="width:12px;height:12px" role="status"></div>
                        Cargando imagen
                    </button>
                    <button class="btn btn-success ml-3" v-else id="submitBtn">Solicitar mantenimiento</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Issue from '@/Classes/Issue';
import {getDataFrom,isEmpty,getToastElement,capitalizeEach} from '@/utils';
import Multiselect from '@vueform/multiselect'
import InputFile from '@/components/InputFile'
import { showError } from '../../utils';
import firebase from "firebase/app";
import "firebase/database";
export default {
    name:'CreateTicket',
    components:{Multiselect,InputFile},
    data() {
        return {
            operations:null,
            issue: new Issue({start_date:Date.now(),by_food:true}),
            error:{issue:{}},
            trigger:{uploading:false},
            equipment:equipment,
            applicant:null,
        }
    },
    mounted() {
        // console.log(getToastElement);
        getDataFrom('https://salida.foodservice.com.ar/api/consumos/operaciones',false).then((data)=>{
            // console.log(data);
            this.operations = data.map((operation)=>{
                return {
                    label: operation.name,
                    value: {name:operation.name, id:operation.id}
                }
            });

            getDataFrom('states/applicant/0',true).then((data)=>{
                // console.log(data);
                let user = JSON.parse(sessionStorage.getItem('userData'));
                this.applicant = {
                    from_name: user.leg_apellido + " " +user.leg_nombre,
                    from_dni: parseInt(user.leg_numdoc),
                    state:data
                }
            })
        })
    },
    methods: {
        handleOperationSelect(selection){
            this.issue.operation_id = selection.id;
            this.issue.operation_name = selection.name;
            this.setTechnicianInOperation(selection.id).then((o)=>{
                if(o!=null){
                    let toast = getToastElement(`El técnico ${capitalizeEach(o.from_name)} fué asignado a este ticket`,'success');
                    document.body.appendChild(toast);
                    setTimeout(() => {
                        toast.remove();
                    }, 3500);
                }
            })

        },
        handleFilesUpload(files){
            this.issue.images = files;
        },
        submit(e){
            e.preventDefault();

            let check = ['issue_type','description','equipment','operation_id'];
            let valid = true;

            check.forEach((p)=>{
                delete this.error.issue[p];
                if(isEmpty(this.issue[p])){
                    valid=false;
                    this.error.issue[p] = 'Este es un campo obligatorio';
                }
            });

            // El formulario no es valido, una prop de check esta vacia (null o '')
            if(!valid) return null;

            this.triggerLoadForm(e.target.submitBtn,true,'Guardando solicitud');

            // Set the applicant created on mounted
            this.issue.applicant = this.applicant;
            // delete this.issue.state;
            delete this.issue.buyer;

            // Save the new issue
            this.issue.store().then((issue_id)=>{
                /**
                 * Set the initial state
                 * */ 

                // Get the issue initial state
                getDataFrom('states/issue',true).then((data)=>{
                    
                    let issueState = data[Object.keys(data).at(0)];
    
                    let user = JSON.parse(sessionStorage.getItem('userData'));
                    
                    // this.issue.state = {};
                    // let now = Date.now();
                    // let epochId = now.toString(16);
                    
                    // this.issue.state[epochId] = 

                    firebase.database().ref(`issues/${issue_id}/state`).push({
                        timestamp: Date.now(),
                        observation:'',
                        label:issueState.label,
                        color:issueState.color,
                        from_name: user.leg_apellido + " " +user.leg_nombre,
                        from_dni: parseInt(user.leg_numdoc),
                    }).then(()=>{
                        this.$emit('ended',true);
                    })


                }).catch((e)=>showError(e,'Error al obtener el estado inicial del ticket, compruebe su conexión a internet o pruebe actualizar la página (si actualiza perdera la iformación actual).'));

            }).catch((e)=>showError(e,'Error al guardar el ticket, compruebe su conexión a internet o pruebe actualizar la página. (si actualiza perdera la iformación actual)'));


        },
        triggerLoadForm(button,mode,label){
            // Se actualiza la vista para mostrar es estado de carga
            button.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> ${label}
            `
            button.disabled = mode;
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = mode;
            }
        },
        setTechnicianInOperation(operation_id){
            return new Promise((resolve,reject)=>{
                getDataFrom(`operations/${operation_id}`, true).then((operation)=>{
                    if(operation!=null){
                        let technician = {
                            from_dni:operation.technician_dni,
                            from_name:operation.technician_name,
                            timestamp:Date.now()
                        }
                        this.issue['technician'] = technician;
                        resolve(technician);
                    }else {resolve(null)}
                }).catch((e)=>{showError(e,'Error al obtener al técnico asignado en la operacion'); reject(e)})
            });
        }
    },

}

var equipment = [
    "Anafe", 
    "Bachas", 
    "Burletes", 
    "Cámara De Refrigerados", 
    "Campana", 
    "Desagues", 
    "Edilicio (Azulejos, Paredes, Ventanas, Puertas, Piso, Etc)", 
    "Freezers", 
    "Heladera Exhibidora", 
    "Hornos", 
    "Iluminación", 
    "Fuegos", 
    "Freidora", 
    "Heladera", 
    "Parrilla", 
    "Línea Caliente", 
    "Línea Fría", 
    "Plancha", 
    "Instalaciones Varias", 
    "Cuchi Pasta", 
    "Heladera Bajo Mesada", 
    "Baño María", 
    "Maquinaria Pequeña", 
    "Heladera 4 Puertas", 
    "Electricidad", 
    "Heladera 2 Puertas", 
    "Marmita", 
    "Calienta Platos", 
    "Batidora", 
    "Microondas",
    "Minipimer", 
    "Montacargas", 
    "Cámara De Congelados", 
    "Cortadora De Fiambres",
    "Otro"
];
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-blue {
    --ms-tag-bg: #DBEAFE;
    --ms-tag-color: #2563EB;
    --ms-ring-color: #2563EB20;
    
    --ms-tag-font-size: 0.8rem;

    --ms-border-color: #282f3ae6;
    --ms-py: 0.375rem;
    --ms-px: 0.75rem;

    --ms-option-font-size: .8rem;
    
    --ms-dropdown-border-color: #282f3ae6;
}

.multiselect-single-label {
    font-size: .875rem;
}

.radio-control{
    font-size: 1rem;
    margin-right: 2rem;
}

</style>