<template>
    <template v-if="entity">
        <h5 v-if="!trigger.setState" class="font-weight-normal">
            Estado actual:<br>
            <div class="badge mr-2 py-2" :class="'badge-'+stateSelected.color">{{stateSelected.label}}</div>
            <button class="btn btn-primary btn-sm p-1 px-2" @click.prevent="trigger.setState=true" :disabled="!states || stateSelected.close">
                <svg xmlns="http://www.w3.org/2000/svg" style="width:14px; height:14px" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16" data-v-4451b5ea=""><path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" data-v-4451b5ea=""></path></svg>
                Cambiar
            </button>
        </h5>
        <div class="row mt-2" v-if="entity.state!=undefined && !trigger.setState">
            <div class="col-12">
                <div class="bg-light p-3 rounded">
                    Observación: {{getCurrentState(entity.state).observation}}
                </div>
            </div>
            <div class="col-12" v-if="getCurrentState(entity.state).due_date">
                <div class="bg-light p-3 rounded">
                    Fecha de vencimiento: {{dateFormateR(getCurrentState(entity.state).due_date)}}
                </div>
            </div>
            <div class="col-12" v-if="getCurrentState(entity.state).files">
                <div class="bg-light p-3 rounded">
                    Documentos: <img :src="img" class="tiny-thumb" v-for="img,i in getCurrentState(entity.state).files" :key="i" @click="showModalImage(img)">
                </div>
            </div>
        </div>
        <form class="form-row" v-if="trigger.setState">
            <div class="col-12 mb-2">
                <label class="form-label">Estado:</label>
                <select class="form-select" v-model="stateSelected" @change="handleStateSelection()">
                    <option :value="data" v-for="(data,s) in states" :key="s">{{data.label}}</option>
                </select>
            </div>
            <div class="col-12 mb-2">
                <label class="form-label">Observación</label>
                <input 
                    type="text" 
                    class="form-control" 
                    :placeholder="(error.state.observation) ? 'La observación es obligatoria' : 'Observación'" 
                    :class="{'invalid-form':error.state.observation}" 
                    v-model="newState.observation"
                    @change="error.observation=false"
                >
                <div class="text-danger" v-if="this.error['observation']">&otimes; El campo es obligatorio</div>
            </div>
            <div class="col-12 mb-2" v-if="stateSelected.due_date != undefined && stateSelected.due_date == true">
            <label class="form-label">Vencimiento</label>
                <input 
                    
                    type="date" 
                    class="form-control" 
                    :placeholder="(error.state.due_date) ? 'La fecha es obligatoria' : 'Fecha de vencimiento'" 
                    :class="{'invalid-form':error.state.due_date}" 
                    v-model="newState.due_date"
                    @change="error.due_date=false"
                >
                <div class="text-danger" v-if="this.error['due_date']">&otimes; El campo es obligatorio</div>
            </div>
            <div class="col-12 mb-2" v-if="stateSelected.files != undefined && stateSelected.files == true">
            
                <!-- <input 
                    
                    type="date" 
                    class="form-control" 
                    :placeholder="(error.state.due_date) ? 'La fecha es obligatoria' : 'Fecha de vencimiento'" 
                    :class="{'invalid-form':error.state.due_date}" 
                    v-model="newState.due_date"
                    @change="error.state.due_date=false"
                > -->
                <input-file :titulo="'Seleccionar archivos'" :pdf="true" @uploading="trigger.uploading = $event" :multiple='true' :error="{estado: false,mensaje:''}" @seleccion="handleFiles($event)"></input-file>
                <div class="text-danger" v-if="this.error['files']">&otimes; El campo es obligatorio</div>
            </div>
            <div class="col-12 mb-2 mt-4 text-right">
                <button class="btn btn-light mr-2 " @click.prevent="cancel()">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg> -->
                    Cancelar
                </button>
                <button class="btn btn-success" disabled v-if="trigger.uploading">Guardando archivos..</button>
                <button class="btn btn-success" @click.prevent="submit()" v-else>Guardar</button>
            </div>
        </form>
        <div class="text-danger" v-if="!trigger.setState && trigger.stateDeleted">&otimes; El estado actual fue eliminado</div>
    </template>

    <modal v-if="trigger.showImage" :modalImage="true" @click="trigger.showImage=false">
        <img :src="imageSelected" class="image-modal" @click="trigger.showImage=false">
    </modal>
</template>

<script>
import { getDataFrom,showError,capitalizeEach,isset,dateFormat} from "@/utils";
// Classes
import Buyer from '../Classes/Buyer';
import Technician from '../Classes/Technician';
import Issue from '../Classes/Issue'
// Compo
import InputFile from './InputFile.vue';
import Modal from './Modal.vue'
const user = JSON.parse(sessionStorage.getItem('userData'));
export default {
    name:"StatesManager",
    components:{InputFile,Modal},
    props:{
        // who:Object,
        entityType:String,
        entityData:Object,
        selected:Object,
        issueId:Object,
        issueMode:Boolean,
        opened:{
            default:false,
            type:Boolean
        },
    },
    data() {
        return {
            entity:null,
            states:null,
            newState:{
                label:null,
                color:null,
                timestamp:Date.now(),
                from_name:capitalizeEach(user.leg_apellido + " " + user.leg_nombre),
                from_dni:user.leg_numdoc,
                observation:null,
            },
            stateSelected:null,
            trigger:{
                setState:false,
                noObservation:false,
                uploading:false,
                showImage:false,
                stateDeleted:false,
            },
            imageSelected:null,
            error:{
                state:{
                    observation:false,
                    due_date:false,
                    files:false,
                }
            }
        }
    },
    mounted() {
        // console.log(`states/${this.entityType.toLowerCase()}`);
        getDataFrom(`states/${this.entityType.toLowerCase()}`,true).then((data)=>{
            this.states = data;

            // Initialize the corresponding entity
            if (this.entityType.toLowerCase() == 'buyer') {
                this.entity = new Buyer(this.entityData);
            }else if(this.entityType.toLowerCase() == 'technician') {
                this.entity = new Technician(this.entityData);
            }else if(this.entityType.toLowerCase() == 'issue'){
                this.entity = new Issue(this.entityData);
            }

            // Set the issue_id
            this.entity.setIssue(this.issueId);

            // Set the current state
            if(!isset(this.entity.state)){
                this.stateSelected = {
                    label: 'Sin asignar',
                    color: 'danger',
                };
            }else {
                let currentState = this.entity.state[Object.keys(this.entity.state).at(-1)];
                
                // console.log(currentState);

                let indexSelected = Object.keys(this.states).find((s)=>{
                    return this.states[s].label.toLowerCase() == currentState.label.toLowerCase();
                });

                if(indexSelected!=undefined){
                    this.stateSelected = this.states[indexSelected];
                    this.newState.label = this.states[indexSelected].label;
                }else {
                    this.stateSelected = currentState;
                    this.newState.label = null;
                    this.trigger.stateDeleted = true;
                }

            }

            this.trigger.setState = this.opened;
        }).catch((error)=>showError(error,"No fue posible obtener los estados, pruebe actualizar la página para reintentar"));

    },
    methods: {
        handleFiles(images){
            this.newState['files'] = images;
            if(this.trigger.uploading){
                this.trigger.uploading--;
                if (this.trigger.uploading < 0) this.trigger.uploading = 0;
            }
        },
        handleStateSelection(){
            Object.assign(this.newState,this.stateSelected);
            if(this.stateSelected.files != undefined && this.stateSelected.files==true) this.newState['files'] = [];
            if(this.stateSelected.due_date != undefined && this.stateSelected.due_date==true) this.newState['due_date'] = null;
            if(this.stateSelected.observation != undefined && this.stateSelected.observation==true) this.newState['observation'] = null;
            
        },
        submit(){
            let stateChange = true;

            if(isset(this.entity.state)){
                let currentState = this.entity.state[Object.keys(this.entity.state).at(-1)];
                if(currentState.label.toLowerCase() == this.newState.label.toLowerCase()){
                    stateChange = false;
                }
            }

            // Si no se selecciono un nuevo state o el seleccionado es el mismo que el inicial
            if(!this.newState.label || !stateChange){
                // Se cierra el form
                this.trigger.setState = false;
                // Se emite evento de fin (No se esta agarrando actualmente)
                this.$emit("ended",false);
                return null;
            }

            // // Si no se indicó una observación se lanza error en el form mediante trigger
            // if(!this.newState.observation){
            //     this.trigger.noObservation = true;
            //     // no se guarda nada
            //     return null;
            // }

            let valid = true;
            Object.keys(this.stateSelected).forEach((key)=>{
                // Si la propiedad es true, significa que es un valor que se pide al usuario (due_date, file, observation)
                if(this.stateSelected[key] == true && this.newState[key] == null){
                    valid = false;
                    this.error[key] = true;
                }
            })

            if (!valid) return null;

            // Todo es correcto y se actualiza el estado.
            this.entity.setState(this.newState).then(() => {
                this.entity.refresh().then(()=>{
                    // console.log('listo');
                    // Se cierra el form
                    this.trigger.setState = false;
                    // Se emite evento de fin (No se esta agarrando actualmente)
                    this.$emit("updated",this.entity);
                })
            })



        },
        cancel(){
            // Set the current state
            if(!isset(this.entity.state)){
                this.stateSelected = {
                    label: 'Sin asignar',
                    color: 'danger',
                };
            }else {
                let currentState = this.entity.state[Object.keys(this.entity.state).at(-1)];
                
                let indexSelected = Object.keys(this.states).find((s)=>{
                    return this.states[s].label.toLowerCase() == currentState.label.toLowerCase();  
                });

                this.stateSelected = this.states[indexSelected];
            }
            this.trigger.setState=false
            this.$emit('close');
        },

        getCurrentState(states){
            if(states){
                return states[Object.keys(states).at(-1)];
            }
        },
        dateFormateR(d){return dateFormat(d)},
        showModalImage(src){this.imageSelected = src;this.trigger.showImage=true},
    },
}
</script>

<style>
.invalid-form {
    border-color: #dc3545;
}
.invalid-form::placeholder {
    color: #dc3545;
}

.tiny-thumb {
    width: 42px;
    height: 37px;
    object-fit: cover;
    border: 1px solid #727cf540;
    border-radius: 3px;
    cursor:pointer;
}
</style>