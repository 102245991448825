<template>
    <div>
        <!-- List placeholder -->
        <div class="row" v-if="!trigger.list">
            <div class="col stretch-card py-5">
                <!-- <ListaCarga :card="false" rows="10" class="mt-2"/> -->
                <div class="spinner-border text-primary mx-auto my-5 p-4" style="width:64px;height:64px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> 
            </div>
        </div>
        <!-- List -->
        <div v-else>
            <!-- <h4 class="mb-2">Solicitudes de mantenimiento</h4> -->
            <!-- <div class="border border-secondary p-3 rounded mb-3">
                <div class="row mb-2">
                    <div class="col-12 col-md mb-2">
                        Filtrar por fecha
                        <div class="d-flex mt-1">
                            <div class="d-flex align-items-center text-dark border border-dark border-right-0 bg-light rounded-left px-2">
                                <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                Desde
                            </div>
                            <input type="date" class="form-control mr-2" style="border-radius:0 .25rem .25rem 0 !important" v-model="dateFilter.start" >
                            <div class="d-flex align-items-center text-dark border border-dark border-right-0 bg-light rounded-left px-2">
                                <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                Hasta
                            </div>
                            <input type="date" class="form-control" style="border-radius:0 .25rem .25rem 0 !important" v-model="dateFilter.end" >
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mb-2">
                        Filtrar por prioridad
                        <div class="d-flex mt-1">
                            <div class="d-flex align-items-center text-dark border border-dark border-right-0 bg-light rounded-left px-2">
                                <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom: 2px;" width="16" height="16" viewBox="0 0 384 512" fill="currentColor" ><path d="M216 23.86c0-23.8-30.65-32.77-44.15-13.04C48 191.85 224 200 224 288c0 35.63-29.11 64.46-64.85 63.99-35.17-.45-63.15-29.77-63.15-64.94v-85.51c0-21.7-26.47-32.23-41.43-16.5C27.8 213.16 0 261.33 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-193-168-296.14z"/></svg>
                                Prioridad
                            </div>
                            <select class="form-select" style="border-radius:0 .25rem .25rem 0 !important" v-model="priorityFilter">
                                <option value="0" selected>Todas</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mb-2 text-right text-md-left">
                        Establecer filtros<br>
                        <button class="btn btn-sm btn-outline btn-outline-danger mt-1" @click.prevent="clearFilterTable()">Borrar filtros</button>
                        <button class="btn btn-sm btn-primary ml-2 mt-1" @click.prevent="drawTable()">Filtrar</button>
                    </div>
                </div>
            </div> -->
            
            <div class="table-responsive">

                <table :id="tableId" class="table" style="width:100%">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Fecha</th>
                            <th>Operación</th>
                            <th>Motivo</th>
                            <th>Estado</th>
                            <th>Solicitante</th>
                            <th>Comprador</th>
                            <th>Técnico</th>
                            <th>Prioridad</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(issue, i) in issues" :key="i" :class="{'overdue':overdueTicket(issue)}">
                            <td :data-order="issue.start_date"></td>
                            <td>
                                <button class="btn btn-primary btn-xs" @click.prevent="$emit('show',{...issue,...{fireId:i}})">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -4px; width: 1rem; height: 1rem;" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16"> <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/> <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/> </svg>
                                    Detalle
                                </button>
                            </td>
                            <td>{{dateFormateR(issue.start_date)}}</td>
                            <td>
                                <Popper
                                    arrow
                                    disableClickAway="false"
                                    placement="top"
                                    hover
                                    :content='"» "+issue.operation_name'
                                >
                                    <div class="list-short-text">
                                        <!-- {{issue.operation_name}} -->

                                            {{issue.operation_name}}
                                    </div>
                                </Popper>
                            </td>
                            <td>
                                <div class="d-flex align-items-center justify-content-between pr-3">
                                    <div style="">
                                        {{issue.issue_type}}
                                        <br>
                                        {{issue.equipment}}
                                    </div>
                                    <Popper
                                        arrow
                                        disableClickAway="false"
                                        placement="top"
                                        hover
                                        :content="'» ' + issue.description"
                                    >
                                        <button class="btn btn-light btn-circle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                        </button>
                                    </Popper>
                                </div>
                            </td>
                            <td>
                                <div class="badge" :class="'badge-'+getCurrentState(issue.state).color">
                                    {{capitalizeR(getCurrentState(issue.state).label)}}
                                </div>
                            </td>
                            <td>{{(issue.applicant) ? issue.applicant.from_name : 'ERROR APLI'}}</td>
                            <!-- <td>
                                {{(tickets[issue.purchase_id] != undefined) ? tickets[issue.purchase_id].from_name : 'Sin asignar'}}
                                <button class="btn btn-sm btn-primary" v-if="tickets[issue.purchase_id] == undefined">Set</button>
                            </td> -->

                            <td>
                                <!-- <div class="badge badge-danger" v-if="issue.buyer==undefined">Sin asignar</div> -->
                                <div class="d-flex">
                                    <div class="table-pre-select" v-if="setBuyer!=i" @mouseover="handleMouseOver($event)" @mouseleave="handleMouseLeave($event)">
                                        <div class="label-pre-select">
                                            {{(isset(issue.buyer)) ? issue.buyer.from_name : 'Sin asignar'}}
                                        </div>
                                        <button class="btn btn-sm btn-primary" @click.prevent="setBuyer = i">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16"> <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                                        </button>
                                        <div class="no-button"></div>
                                    </div>
                                    <TableFormSelect v-if="setBuyer==i" :options="buyers" :label="['name']" :value="null" @save="handleSelectOption($event,'Buyer',i)"/>

                                    <button class="btn btn-primary ml-2" v-if="isset(issue.buyer)" @click.prevent="$emit('log',{issue:{...issue,...{fireId:i}},entity:issue.buyer,entity_type:'buyer'})">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-square-text-fill" viewBox="0 0 16 16"> <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/> </svg>
                                    </button>
                                    <div class="message-alert-dot" v-if="isset(issue.buyer) && checkNewLog(issue.buyer.logs)"></div>
                                </div>
                            </td>


                            <td>
                                <!-- <div class="badge badge-danger" v-if="issue.technician==undefined">Sin asignar</div> -->
                                <div class="d-flex">
                                    <div class="table-pre-select" v-if="setTechnician!=i" @mouseover="handleMouseOver($event)" @mouseleave="handleMouseLeave($event)">
                                        <div class="label-pre-select">
                                            {{(isset(issue.technician)) ? issue.technician.from_name : 'Sin asignar'}}
                                        </div>
                                        <button class="btn btn-sm btn-primary" @click.prevent="setTechnician = i">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16"> <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                                        </button>
                                        <div class="no-button"></div>
                                    </div>
                                    <TableFormSelect v-if="setTechnician==i" :options="technicians" :label="['name']" :value="null" @save="handleSelectOption($event, 'Technician' ,i)"/>

                                    <button class="btn btn-primary ml-2" v-if="isset(issue.technician)" @click.prevent="$emit('log',{issue:{...issue,...{fireId:i}},entity:issue.technician,entity_type:'technician'})">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-square-text-fill" viewBox="0 0 16 16"> <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/> </svg>
                                    </button>
                                    <div class="message-alert-dot" v-if="isset(issue.technician) && checkNewLog(issue.technician.logs)"></div>
                                </div>
                            </td>

                            <td>
                                <set-ticket-priority v-if="i==setPriority" :ticket="issue" :id="i" @end="handleUpdatePriority($event)"></set-ticket-priority>

                                <div class="priority-group" v-else>
                                    <div class="priority-label">{{(isset(issue.priority)) ? issue.priority : 5}}</div>
                                    <button class="priority-button" @click.prevent="setPriority=i">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"> <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/> </svg>
                                    </button>
                                </div>
                            </td>

                            <td>
                                <button class="btn btn-warning py-2" v-if="!getCurrentState(issue.state).close" @click.prevent="$emit('closeIssue',{...issue,...{fireId:i}})">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.3rem;" width="16" height="16" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/> <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/> </svg> -->
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.3rem;" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg> -->
                                    <svg viewBox="0 0 24 24" style="vertical-align: -0.3rem;" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
                                    Estado
                                </button>
                                <div class="badge badge-success" v-else>
                                    Cerrado: {{dateFormateR(getCurrentState(issue.state).timestamp)}}
                                </div>
                            </td>

                            <!-- <td class="d-flex flex-wrap w-74"><div class="badge badge-light mr-2 mb-2" v-for="(category,i) in issue.categories" :key="i">{{sortedCategories[category].label}}</div></td> -->
                            <!-- <td>
                                <div class="btn btn btn-primary ml-2" @click.prevent="$emit('show',{...issue,...{fireId:i}})">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="vertical-align: none;" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                    Ver
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import { getDataFrom, showError,capitalize} from "@/utils";
import $ from 'jquery'
import TableFormSelect from '../TableFormSelect.vue';
import SetTicketPriority from '../SetTicketPriority.vue';
import Popper from "vue3-popper";
// import ListaCarga from '@/components/ListaCarga.vue';
/*eslint-disabled*/
import Buyer from '../../Classes/Buyer';
import Technician from '../../Classes/Technician';
// import Issue from '../../Classes/Issue'
/* eslint-enabled */
export default {
    name:'ListIssues',
    components:{TableFormSelect,Popper,SetTicketPriority,},
    props: {givenIssues:Array,buyers:Object,technicians:Object},
    data() {
        return {
            issues:null,
            // buyers:null,
            // technicians:null,
            setTechnician:null,
            setBuyer:null,
            setPriority:null,

            dateFilter:{
                start:null,
                end:null,
            },
            priorityFilter:0,

            table:null,
            tableId:null,

            trigger:{
                list:false,
            },

            today:new Date(),
        }
    },
    mounted(){
        // $('#datatable').dataTable().fnDestroy();

    //    setTimeout(() => {
            // let entitites = ['issues','buyers','technicians'];
            // let promises = entitites.map((entity) => {
            //     return getDataFrom(entity,true).then((data)=>{
            //         this[entity] = data;
            //     }).catch((error)=>{showError(error,`No logramos obtener la lista de ${entity}, intente actualizar la página.`)});
            // });
            this.issues = JSON.parse(JSON.stringify(this.givenIssues));
            this.tableId = Math.random().toString(36).substring(2);

                // let vue = this;

                // $.fn.dataTable.ext.search.push( function( settings, data ) {
                //     if(vue.dateFilter.start == null && vue.dateFilter.end == null && (vue.priorityFilter == null || vue.priorityFilter == 0)) return true;

                //     // No matchea hasta que se demuestre lo contrario
                //     let matched = false;

                //     if(vue.dateFilter.start != null) {
                //         var min = new Date(vue.dateFilter.start).getTime();
                //         var max = new Date(vue.dateFilter.end).getTime();
                //         var date = new Date(data[2]).getTime(); 
        
                //         if (min == null && max == null) matched = true
                //         if (min == null && date <= max) matched = true
                //         if (max == null && date >= min) matched = true
                //         if (date <= max && date >= min) matched = true
                //     }
                    
                //     if(matched && vue.priorityFilter == 0) {
                //         matched = true;
                //     } else if(matched && data[9] == vue.priorityFilter) {
                //         return true;
                //     } else if(matched && data[9] != vue.priorityFilter){
                //         return false;
                //     } else if((!isset(vue.dateFilter.start) || !isset(vue.dateFilter.end)) && data[9] == vue.priorityFilter){
                //         matched = true;
                //     }
                    
                //     return matched;
                // });

                // this.trigger.list = true;

                // let config = JSON.parse(JSON.stringify(tableConfig));
                // config.buttons = [ 'copy', {
                //     extend: 'excelHtml5',
                //     exportOptions: {
                //         columns: [2,3,4,5,6,7,8,9],
                //         format: {
                //             body: function (data, row, column) {
                //                 if (column == 2 || column == 1) {
                //                     return data.split('»')[0].replace(/(&nbsp;|<([^>]+)>)/ig, "");
                //                 }else if (column == 5 || column == 6) {
                //                     return data.split('-->')[0].replace(/(&nbsp;|<([^>]+)>)/ig, "");
                //                 }else {
                //                     return data.replace(/(&nbsp;|<([^>]+)>)/ig, "");
                //                 }
                //             }
                //         }
                //     }
                // }, 'csv', 'pdf' ];
                // config["order"] = [0,'desc'];
                // config["pageLength"] = 5;
                // config['columnDefs']= [
                //     {
                //         'targets': [0],
                //         'visible': false,
                //         'searchable': false
                //     },
                // ];
                setTimeout(() => {
                    this.trigger.list = true;
                    this.$nextTick(()=>{
                        let config = JSON.parse(JSON.stringify(tableConfig));
                        config["order"] = [0,'desc'];
                        config["pageLength"] = 5;
                        this.table = $(`#${this.tableId}`).dataTable().fnDestroy();
                        
                        this.$nextTick(()=>{
                            this.table = $(`#${this.tableId}`).DataTable(config);
                        })
                    })
                }, 500);
            // Promise.all(promises).then(()=>{
                

            // });
    //    }, 400);
    },
    methods:{
        drawTable(){
            this.table.draw();
        },
        clearFilterTable(){
            this.dateFilter = {
                start:null,
                end:null,
            }

            this.priorityFilter = 0;

            this.table.draw();
        },
        getCurrentState(states){
            let s = {label:'Sin asignar',color:'danger'};
            if(states){
                s = states[Object.keys(states).at(-1)];
            }
            return s;
        },
        handleUpdatePriority(){
            this.$emit('updated',true);
            // if(updated){
            //     getDataFrom('issues',true).then((data)=>{
            //         this.issues = data;
            //         this.setPriority = null;
            //         this.$nextTick(()=>{
            //             $('#datatable').dataTable().fnDestroy();
            //             let config = JSON.parse(JSON.stringify(tableConfig));
            //             config.buttons = [
            //                 'copy',
            //                 'excel',
            //                 'csv',
            //                 'pdf'
            //             ];
            //             config["order"] = [0,'desc'];
            //             config['columnDefs']= [
            //                 {
            //                     'targets': [0],
            //                     'visible': false,
            //                     'searchable': false
            //                 },
            //             ],
            //             $('#datatable').DataTable(config);
            //         })
            //     }).catch((error)=>{showError(error,`No logramos obtener la lista de isues, intente actualizar la página.`)});
            // }
        },
        handleSelectOption(selected,entity_type,issueId){
            // Lo ideal seria aprovecha el polimorfismo, pasar como parámetro la instancia de Buyer o Technician
            // Y usar las entidades en forma polimorfica
            // Pero al venir desde el template de Vue no es posible instanciar la clase.

            let entity;
            // Se obtiene el tipo de entidad y se instancia mediante if
            if(entity_type.toLowerCase() == 'buyer'){
                entity = new Buyer();
            }else if(entity_type.toLowerCase() == 'technician'){
                entity = new Technician();
            } else {
                console.error('La entidad <entity_type> no existe');
                return null;
            }

            entity.setTicketSender(selected.dni,selected.name);
            entity.setIssue(issueId);

            entity.setInitialState().then(()=>{
                // console.log('El estado d el ticket fué actualizado');
                entity.store().then(()=>{
                    // console.log('El buyer fue creado y relacionado al issue');
                    getDataFrom('issues',true).then((data)=>{
                        this.issues = data;
                        this.setTechnician = null;
                        this.setBuyer = null;
                        this.$nextTick(()=>{
                            $('#datatable').dataTable().fnDestroy();
                            $('#datatable').DataTable(tableConfig);
                        })
                    }).catch((error)=>{showError(error,`No logramos obtener la lista de isues, intente actualizar la página.`)});

                }).catch((error)=>{showError(error,'Hubo un error al actualizar el comprador, presione Cancelar para reintentar o actualice la página.')})
            }).catch((error)=>{showError(error,'Hubo un error al obtener los estados de tickets, es posible solucionarlo actualizando la página, tenga en cuenta que el trabajo no guardado podría perderse.')})

            // console.log('enti',entity); 


        },
        handleMouseOver(event){
            // console.log(event);
            if(event.target.className == "table-pre-select"){
                event.target.childNodes[1].style.display = "block";
            }

        },
        handleMouseLeave(event){
            // console.log(event);
            if(event.target.className == "table-pre-select"){
                event.target.childNodes[1].style.display = "none";
            }

        },
        checkNewLog(logs){
            if(logs==undefined) return false;
            // console.lsog(logs);
            let arrayLog = Object.keys(logs).map((k)=>{
                return logs[k];
            });

            arrayLog.sort((a,b)=>a.timestamp - b.timestamp);
            // console.log(arrayLog);

            // let readedMessages = JSON.parse(localStorage.getItem("readedMessages"));

            // let readedMessages.some((message_id)=>{
            //     return message_id == arrayLog[arrayLog.length - 1]
            // })

            let user = JSON.parse(sessionStorage.getItem('userData'));



            return arrayLog[arrayLog.length - 1].from_dni != user.leg_numdoc;
        },
        isset(e){
            return( e!=undefined && e!=null && e!="");
        },
        capitalizeR(string){return capitalize(string)},
        dateFormateR(date){ 
            let d = new Date(date);
            let month = (d.getMonth() + 1).toString();
            let day = d.getDate().toString();
            let year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        },
        overdueTicket(issue){
            let state = this.getCurrentState(issue.state);
            
            if(state['close'] == undefined && !state['close']){
                let open = new Date(issue.start_date);
                let days_open = this.days(this.today, open)
                
                if(days_open>=30){
                    return true;
                }
            }

            return false
        },
        days(date_1, date_2){
            let difference = date_1.getTime() - date_2.getTime();
            let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
            return TotalDays;
        }
    },
}
</script>

<style scoped>
.table-pre-select > * {
    font-size: 0.88rem !important;
    font-weight: normal !important;
}

.table-pre-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .22rem .8rem;
    border-radius: 0.1875rem;
    transition: all .2s ease-in-out;
    padding-right: .22rem;
    border:1px solid #ededed;
    width: 160px;
}

.table-pre-select:hover{
    background: #ededed;
}

.label-pre-select {
    padding: 0.6rem;
    padding-left: 0;
    width:80%;
    overflow: hidden;
    text-overflow: ellipsis;
}


.table-pre-select .btn{
    padding: .3rem;
    padding-bottom: .2rem;
    transition: all .2s ease-in-out;
    display:none;
}


.btn-circle {
    border-radius: 100%;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-circle svg {
    width: 1.48rem;
    height: 1.48rem;
}


.priority-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 3px;
    padding: 2px;
    width:90%
}
.priority-group > * {
    padding: 0.35rem 0.75rem;
}

.priority-label {
    width: 56%;
    background: #f7f7f7;
    font-size: 0.98rem;
    border-radius: 3px;
    padding: .7rem .75rem;
}

.priority-button {
    border: none;
    width: 40%;
    background: #727cf5;
    border-radius: 3px;
    color: #fff;
    padding: 0.7rem;
}
/* .priority-button svg {
    width:18px;
    height:18px;
} */

.message-alert-dot {
    position: relative;
    background: red;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transform: translate(-8px,-2px);
}


.list-short-text {
    width:10vw;
    text-overflow: ellipsis;
    overflow: hidden;
}

table.dataTable td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.overdue {
    background:#ff005515;
}
</style>
