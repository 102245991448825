<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mb-0">{{view.description}}</p>
              <!-- <div class="w-50 text-left text-md-right">
                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <button type="button" class="btn btn-primary" @click.prevent="refreshTriggers('create')">
                    Crear {{view.title.toLowerCase()}}
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Create -->
    <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create 
              :roleType="selected"
              @end="handleEnd($event)"
            ></create>
          </div>
        </div>
      </div>
    </div>
    <!-- List -->
    <div class="row" v-if="trigger.list">
      <div class="col-12 mb-4 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h4 class="">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                Estados generales
              </h4>
              <button class="btn btn-primary d-flex align-items-center" @click.prevent="handleCreate('issue')">Nuevo &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              </button>
            </div>
            <list
              :states="states.issue"
              :roleType="'issue'"
              @update="handleUpdate($event,'issue')"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h4 class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                Estados de compra
              </h4>
              <button class="btn btn-primary d-flex align-items-center" @click.prevent="handleCreate('buyer')">Nuevo &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              </button>
            </div>
            <list
              :states="states.buyer"
              :roleType="'buyer'"
              @update="handleUpdate($event,'buyer')"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h4 class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
                Estados de mantenimiento
              </h4>
              <button class="btn btn-primary d-flex align-items-center" @click.prevent="handleCreate('technician')">Nuevo &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              </button>
            </div>
            <list
              :states="states.technician"
              :roleType="'technician'"
              @update="handleUpdate($event,'technician')"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
    </div>
    <!-- Update -->
    <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update
              :selected="selection"
              :roleType="updateType"
              @edit="handleEvent('update',$event)" 
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDataFrom,capitalize,showError} from '@/utils';
// import $ from "jquery";
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

import Create from '@/components/State/CreateState.vue';
import List from '@/components/State/ListStates.vue';
import Update from '@/components/State/UpdateState.vue';

export default {
  name: "States",
  components:{
    Create,
    List,
    Update,
  },
  data(){
    return {
      view:{title:'Estados',description:'Agregue y modifique estados para tickets.'},
      entity:'states',

      selected:null,
      updateType:null,

      states:{
        buyer:[],
        technician:[],
        issue:[]
      },

      trigger:{
        create:false,
        update:false,
        list:false,
      }
    }
  },
  mounted(){
    this.updateAndList();
  },
  methods:{
    handleCreate(roleType){
      this.selected = roleType;
      this.refreshTriggers('create');
    },
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    updateAndList(){
      getDataFrom('states',true).then((data)=>{
        if(data) { this.states=data; } 
        this.refreshTriggers('list');
      }).catch((err)=>this.errorLog(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.')); 
    },
    handleEnd(update){
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    handleUpdate(state,type){
      this.updateType = type;
      this.handleEvent('update',state);
    },  
    handleDelete(data){
      Swal.fire({
        title: '¿Vas a eliminar el estado?',
        text: "¡Cuidado! Esta acción no se puede deshacer. No te preocupes por los tickets existentes, mantendrán este estado en su historial.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.database().ref(`states/${data.roleType}/${data.fireId}`).remove().then(()=>{
            // this.trigger.list = false;
            setTimeout(()=>{
              this.updateAndList();
            },200);
          }).catch((e)=>{showError(e,"Se produjo un error al eliminar el estado, cancele para reintentar o actualice la página y vuelva a intentar.")});
        }
      })
    },
    capitalizeR(t){return capitalize(t)}
  }
}
</script>

<style>

</style>