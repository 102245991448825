<template>
    <div>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h4 class="">Asignando operaciones al técnico: <div class="badge badge-light">{{this.selected.name}}</div></h4>

            <div>
                <!-- <button class="btn btn-light mr-3">Cancelar</button> -->
                <button class="btn btn-success" @click="submit()">
                    <svg viewBox="0 0 24 24" style="vertical-align: middle !important;" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                    Guardar
                </button>
            </div>
        </div>
        <form>
            <div class="form-row">
                <div class="col">
                    <input type="text" class="form-control" v-model="searchInput" placeholder="Busque operaciones..">
                </div>
                <div class="col">
                    <input type="checkbox" class="d-none" id="only-selected">
                    <label 
                        class="checkbox-input" 
                        :class="{'text-success':trigger.onlySelected}"
                        for="only-selected" 
                        tabindex=0 
                        @keydown.space="triggerProp('onlySelected')" 
                        @click="triggerProp('onlySelected')"
                    >
                        <!-- On -->
                        <!-- <svg v-if="trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="#10b759" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> -->
                        <svg v-if="trigger.onlySelected" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                        <!-- Off -->
                        <!-- <svg v-if="!trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg> -->
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                        <div class="ml-2" style="padding-top: 2px;">Mostrar solo operaciones seleccionadas</div>
                    </label>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <div class="checkbox-operations-container" v-if="!trigger.onlySelected">
                        <div class="checkbox-operation" v-for="(operation, o) in filteredOperations(freeOperations)" :key="o">
                            <input type="checkbox" class="d-none" id="only-selected">
                            <label 
                                class="checkbox-input checkbox-input-border" 
                                :class="{'checkbox-input-border-success text-success':selectedOperations[operation.id]!=undefined}"
                                for="only-selected" 
                                tabindex=0 
                                @keydown.space="handleCheckOperation(operation)" 
                                @click="handleCheckOperation(operation)"
                            >
                                <!-- On -->
                                <!-- <svg v-if="trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="#10b759" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> -->
                                <svg v-if="selectedOperations[operation.id]!=undefined" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                                <!-- Off -->
                                <!-- <svg v-if="!trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg> -->
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                                <div class="ml-2" style="padding-top: 2px;">{{operation.name}}</div>
                            </label>
                        </div>
                    </div>

                    <div class="checkbox-operations-container" v-else>
                        <div class="checkbox-operation" v-for="(operation, o) in filteredOperations(selectedOperations)" :key="o">
                            <input type="checkbox" class="d-none" id="only-selected">
                            <label 
                                class="checkbox-input checkbox-input-border" 
                                :class="{'checkbox-input-border-success text-success':selectedOperations[operation.id]!=undefined}"
                                for="only-selected" 
                                tabindex=0 
                                @keydown.space="handleCheckOperation(operation)" 
                                @click="handleCheckOperation(operation)"
                            >
                                <!-- On -->
                                <!-- <svg v-if="trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="#10b759" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg> -->
                                <svg v-if="selectedOperations[operation.id]!=undefined" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#10b759" class="bi bi-toggle-on" viewBox="0 0 16 16"> <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/> </svg>
                                <!-- Off -->
                                <!-- <svg v-if="!trigger.onlySelected" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg> -->
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16"> <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/> </svg>
                                <div class="ml-2" style="padding-top: 2px;">{{operation.name}}</div>
                            </label>
                        </div>
                    </div>


                </div>
            </div>
            
        </form>
    </div>
</template>

<script>
import { getDataFrom, isset } from "../../utils";
import firebase from "firebase/app";
import "firebase/database";

export default {
    props:{operations:Object,selected:Object},
    data() {
        return {
            selectedOperations:{},
            freeOperations:{},
            trigger: {
                onlySelected:false,
            },
            searchInput:null,
        }
    },
    mounted() {
        // getDataFrom('operations',true,`orderBy="technician_dni"&equalTo=${this.selected.dni}`).then((data)=>{
        //     this.selectedOperations = data;
        // });
        console.log(this.operations);
        getDataFrom('operations',true).then((assignedOperations)=>{
            if(assignedOperations){
                // Recorre operaciones en consumos
                this.operations.forEach((operation)=>{
                    // Comprueba si la operacion iterada se encuentra asignada en assignedOpe.. (DB de mantenimiento)
                    if(assignedOperations[operation.id]!=undefined){
                        // Si esta asignada al tecnico actual seleccionado
                        if(assignedOperations[operation.id].technician_dni == this.selected.dni){
                            // Se agrega en selectedOperations
                            this.selectedOperations[operation.id] = assignedOperations[operation.id];
                            // Y en operaciones libres (estas se listan)
                            this.freeOperations[operation.id] = operation;
                        }else {
                            // Operations de otros tecnicos no se listan
                            // console.log(operation.name,'esta asignada');
                        }
                    }else {
                        // Si no fue asignada la operacion se guarda en operaciones libres para ser listada y seleccionada
                        this.freeOperations[operation.id] = operation;
                    }
                })
            }else {
                // si no fue seteada ninguna operation (punto inicial)
                this.freeOperations = this.operations;
            }
        });
    },
    methods:{
        submit(){
            // Object.keys(this.selectedOperations).forEach((o)=>{
            //     this.selectedOperations[o]['technician_id']
            // })
            firebase.database().ref(`operations`).update(this.selectedOperations).then(()=>{
                console.log('%c operations has been updated','color:#0f0');
                this.$emit('end',true);
            })
        },
        filteredOperations(operations){
            if (!isset(this.searchInput)) {
                return operations;
            }

            return this.searchBy(operations,this.searchInput);
        },
        searchBy(operations,value){
            let normalizedVal = value.trim().toLowerCase();
            let results = {};
            Object.keys(operations).forEach((o)=>{
                // console.log('busca en',operations[o].name.trim().toLowerCase());
                if(
                    operations[o].name.trim().toLowerCase().includes(normalizedVal) || 
                    operations[o].id == normalizedVal
                ){
                    results[o] = operations[o];
                    // console.log('coincidencia',o);
                }
            });
            return results;
        },
        triggerProp(prop){
            this.trigger[prop]=!this.trigger[prop];
            // console.log(this.trigger[prop]);
        },
        handleCheckOperation(operation){
            if(this.selectedOperations[operation.id]==undefined){
                this.selectedOperations[operation.id] = {
                    name: operation.name,
                    id: operation.id,
                    direccion: operation.direccion,
                    localidad: operation.localidad,
                    provincia: operation.provincia,

                    technician_dni:parseInt(this.selected.dni),
                    technician_name:this.selected.name,
                };
            }else {
                delete this.selectedOperations[operation.id];
            }

            this.$forceUpdate();
        }
    }
}
</script>

<style>
.checkbox-input {
    /* display: inline-block; */
    width: 100%;
    padding: 0.375rem 1.75rem 0.4rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: none;
    border-radius: 0.25rem;
    height: calc(1.3em + 0.75rem);

    display: flex;
    align-items: center;
}

.checkbox-input:focus {
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;

    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
}

.checkbox-input-border {
    border: 1px solid #282f3ae6 !important;
}

.checkbox-input-border-success {
    border-color: #10b759 !important;
    box-shadow: #10b75915 0 0 6px;
}

.checkbox-operation {
    width: 49%;
    margin-bottom: 1.3rem;
}

.checkbox-operation .checkbox-input{
    padding: 1.275rem 1.75rem 1.2rem 0.85rem;
}

.checkbox-operations-container {
    border: 1px solid #282f3a5b;
    border-radius: .25rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    height:60vh;
    overflow-y: scroll;
}
</style>