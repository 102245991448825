<template>
    <div class="accordion" id="accordionExample">
        <div class="card">
            <div class="card-header" id="headingOne">
            <h2 class="mb-0">
                <button class="btn btn-link d-flex align-items-center" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16"> <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/> <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/> </svg>
                    &nbsp;
                    Detalles del incidente
                </button>
            </h2>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    <div class="flex-table">
                        <div class="table-field w-50">
                            Solicita: 
                            <div class="badge badge-light">
                                {{issue.applicant.from_name}}
                            </div>
                        </div>
                        <div class="table-field w-50">
                            Fecha: 
                            <div class="badge badge-light">
                                {{dateFormateR(issue.start_date)}}
                            </div>
                        </div>
                        <div class="table-field w-50">
                            Operación: 
                            <div class="badge badge-light">
                                {{issue.operation_name}}
                            </div>
                        </div>
                        <div class="table-field w-50">
                            Responsable: 
                            <div class="badge badge-light">
                                {{(issue.by_food)?"A cargo de Food Service":"A cargo de la operación"}}
                            </div>
                        </div>
                        <div class="table-field w-50">
                            Equipo: 
                            <div class="badge badge-light">
                                {{issue.equipment}}
                            </div>
                        </div>
                        <div class="table-field w-50">
                            Motivo:
                            <div class="badge badge-light">
                                {{(issue.issue_type)}}
                            </div>
                        </div>
                        <div class="table-field w-100">
                            Descripción:
                            <div class="mt-2 bg-light p-3 rounded">
                                {{issue.description}}
                            </div>
                        </div>
                        <div class="table-field w-100">
                            Imágenes:
                            <div class="mt-2 d-flex">
                                <img :src="img" v-for="(img,i) in issue.images" :key="i" @click="showModalImage(img)" class="thumb-image mr-2">
                            </div>
                        </div>
                        <div class="table-field w-100" v-if="entity">
                            <div class="form-row w-100 align-items-center">
                                <div class="col-12">
                                    <StatesManager
                                        :entityType="entity_type" 
                                        :entityData="entity" 
                                        :issueId="issue.fireId" 
                                        :issueMode="false"
                                        @updated="$emit('update',true)"
                                    />
                                </div>
                            </div>
                            <!-- Estado: -->
                            <!-- <div class="mr-2">Estado:</div>
                            <StatesManager
                                entityType="buyer" 
                                :entityData="entity" 
                                :issueId="issue.fireId" 
                                :issueMode="false" 
                                :selected="entity.state"
                            /> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
                <button class="btn btn-link collapsed d-flex align-items-center" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/> <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/> </svg>
                &nbsp;
                Chat
                </button>
            </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
                <div class="border rounded barder-light">
                    <div class="messages" ref="messageBox">
                        <div v-for="(chat,c) in chats" :key="c" :class="getMessageClass(chat)">
                            <div class="message-content">
                                <span v-if="issetR(chat.text)">{{chat.text}}</span>
                                <img :src="chat.image" v-if="issetR(chat.image)">
                                <div class="message-timestamp">{{dateFormateR(chat.timestamp)}} · {{capitalizeEachR(chat.from_name)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="new-message" v-if="!newImage">
                    <input type="file" accept="image/*" id="input-file" ref="inputFile" @change="handleImagePicked($event)" class="d-none"/>
                    <button class="btn btn-primary" @click.prevent="openFileDialog()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                    </button>
                    <input type="text" class="form-control" v-model="newMessage" @keyup.enter="handleNewMessage()">
                    <button class="btn btn-primary" @click.prevent="handleNewMessage()">Enviar</button>
                </div>
                <div class="new-message-image" v-else>
                    <div class="image-preview">
                        <img :src="newImage">
                    </div>
                    <button class="btn btn-primary" @click.prevent="handleNewImageMessage()" :disabled="trigger.uploading">
                        <div class="spinner-border text-light" role="status" v-if="trigger.uploading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>Enviar</span>
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>
    <modal v-if="trigger.showImage" :modalImage="true" @click="trigger.showImage=false">
        <img :src="imgSelected" class="image-modal" @click="trigger.showImage=false">
    </modal>
</template>

<script>
/* eslint-disable */
import Issue from '@/Classes/Issue';
import Buyer from '@/Classes/Buyer';
import Technician from '@/Classes/Technician';
/* eslint-enable */
import {putBase64,isset,dateFormat, capitalizeEach,getDataFrom,showError} from '@/utils'
import Modal from '../Modal.vue'
// import TableFormSelect from '@/components/TableFormSelect'
import StatesManager from '../StatesManager.vue';

export default {
    name:'Logs',
    components:{
        Modal,
        // TableFormSelect,
        StatesManager
    },
    props:{
        issue:Object,
        from: Object,
        entity_type:String,
        logs:Object,
    },
    data() {
        return {
            newMessage:null,
            newImage:null,
            chats:[],
            entity:null,
            trigger:{uploading:false,showImage:false,setState:false,},
            imgSelected:null,

            states:null,
            
        }
    },
    mounted() {
        if(this.entity_type.toLowerCase() == 'buyer'){
            this.entity = new Buyer(this.issue[this.entity_type.toLowerCase()]);
        }else if (this.entity_type.toLowerCase() == 'technician'){
            this.entity = new Technician(this.issue[this.entity_type.toLowerCase()]);
        }

        this.entity.setIssue(this.issue.fireId);

        if(this.logs != null) {
            Object.keys(this.logs).forEach((i)=>{
                this.chats.push(this.logs[i]);
            });

            this.chats.sort((a,b)=>a.timestamp - b.timestamp);
        }

        
        getDataFrom(`states/${this.entity_type}`,true).then((data)=>{
            this.states = data;
        }).catch((error)=>{showError(error,`No logramos obtener la lista de estados, intente actualizar la página.`)})
        

    //    console.log( this.$refs.messageBox);
    },
    methods: {
        handleNewMessage(){
            if(!isset(this.newMessage) || !this.newMessage.length) return null;
            this.entity.log({text:this.newMessage},this.from).then((timestamp)=>{
                this.chats.push({
                    from_dni:this.from.from_dni,
                    from_name:this.from.from_name,
                    text:this.newMessage,
                    timestamp:timestamp,
                });
                this.newMessage = null;
                this.$nextTick(()=>{
                    this.$refs.messageBox.scrollTop = this.$refs.messageBox.scrollHeight;
                })
                this.$emit('update',true);
            });

        },
        handleNewImageMessage(){
            this.entity.log({image:this.newImage},this.from).then((timestamp)=>{
                this.chats.push({
                    from_dni:this.from.from_dni,
                    from_name:this.from.from_name,
                    image:this.newImage,
                    timestamp:timestamp,
                });

                this.newImage = null;
                this.$nextTick(()=>{
                    this.$refs.messageBox.scrollTop = this.$refs.messageBox.scrollHeight;
                })
            });
        },
        getMessageClass(message){
            let c = 'message-received'; // Por defecto es un mensaje recibido
            
            if(this.entity.from_dni == message.from_dni){
                c = 'message-received';
            }
            
            if(this.from.from_dni == message.from_dni){
                c = 'message-self';
            }
            
            this.$refs.messageBox.scrollTop = this.$refs.messageBox.scrollHeight;

            return c;
        },
        openFileDialog() {
            this.$refs.inputFile.click()
        },
        handleImagePicked(e){
            let file = e.target.files[0];

            let reader = new FileReader()
            reader.onload = (event) => {
                let img = new Image();
                img.src = event.target.result;
    
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = 900
                    const MAX_HEIGHT = 900
                    let width = img.width
                    let height = img.height
    
                    if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                    } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    let data = canvas.toDataURL();

                    this.newImage = data;
                    this.$forceUpdate();
                    this.trigger.uploading = true;
                    
                    putBase64(data).then((url)=>{
                        this.trigger.uploading = false;
                        if (this.newImage) {
                            this.newImage = url;
                        }
                    });
                }
            }
            reader.readAsDataURL(file)
        },
        showModalImage(src){this.imgSelected = src;this.trigger.showImage=true},
        handleSelectOption(data){
            this.entity.setState({
                color: data.color,
                label: data.label,
            });

            this.entity.save().then(() => {
                this.trigger.setState = false;
                this.$emit('update',true);
            }).catch((error)=>{showError(error,'Hubo un error al obtener los estados de tickets, es posible solucionarlo actualizando la página, tenga en cuenta que el trabajo no guardado podría perderse.')});            
        },
        issetR(p){return isset(p)},
        dateFormateR(d){return dateFormat(d)},
        capitalizeEachR(str){return capitalizeEach(str)},
    },
}
</script>

<style scoped>
.messages {
    height: 260px;
    overflow-y: scroll;
}

.message-received, .message-self {
    border-radius: 6px;
    padding: .5rem;
    font-size: smaller;
    width: 80%;
    margin: 1.4rem auto;
    margin-top:.4rem;
}

.message-received {
    background: #3f6c9680;
    color:white;
    margin-left: .4rem;
}
.message-self {
    background: #35a7db;
    color:white;
    margin-right: .4rem;
}

.message-content img {
    width: 100%;
    object-fit: cover;
    max-height: 320px;
}
.message-content {
    font-size:1rem
}

/* .message-self::before{
    position: absolute;
    right: 20px;
    width: 0;
    height: 0;
    content: '';
    border: 10px solid transparent;
    border-left-color: #37a7db;
}

.message-received::after{
    position: absolute;
    right: 20px;
    width: 0;
    height: 0;
    content: '';
    border: 10px solid transparent;
    border-left-color: #3f6c9680;
} */

.new-message {
    display:flex;
    align-items:center;
    margin-top:.8rem;
}

.new-message > * {
    height:32px !important;
}

.new-message input{
    margin: 0 .5rem;
}


.new-message-image{
    margin-top:.8rem;
    display:flex;
}

.image-preview{
    width: 90%;
    height: 92px;
    margin-right: 10px;
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

.message-timestamp {
    font-size: 11px;
    margin-top: 4px;
    color: #000000a8;
}

.thumb-image {
    border-radius: 6px;
    width: 64px;
    height: 64px;
    object-fit: cover;
}



.flex-table {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.table-field {
    padding: 0.6rem;
    border: 1px solid #e8ebf1;
    font-size: 0.9rem;
    margin-top:-1px;
    margin-right: -1px;
}
.table-field.w-100 {
    margin-right: 1px;
}


.accordion > .card .card-header {
    padding: 0.9rem 0.5rem;
}

.accordion > .card .card-body {
    padding: 0.9rem 1.5rem;
}

.card {
    box-shadow:none;
}
</style>